import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import {
  Button ,
  Authenticator,
  Grid,
  Fieldset,
  Flex,
  Card,
  Image,
  View,
  Text,
  Divider,
  Heading,
  Rating

} from '@aws-amplify/ui-react';
import styles from './DMPDCustomerApplause.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../BusinessLogic/uuidv4';
import LeaveComment from '../CommentManagement/LeaveComment';
import { Link } from "react-router-dom";

import  {listProductReviews}  from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api';


const DMPDCustomerApplause = (props) => {      


        DMPDCustomerApplause.propTypes = {
          Page: PropTypes.string,          
          ProductId: PropTypes.string
        };

        const client = generateClient();
        
        const [Page, setPage] = useState(props.Page)   
        const [ProductId, setProductId] = useState(props.ProductId)   
        const [CommentLink, setCommentLink] = useState()   
        const [CustomerProfileData, setCustomerProfileData] = useState("")   
        const [RandomCustomerProfile1, setRandomCustomerProfile1] = useState("")   
        const [RandomCustomerProfile2, setRandomCustomerProfile2] = useState("")  
        const [RandomCustomerProfile3, setRandomCustomerProfile3] = useState("")  

        const [RandomCustomerProfile1Rating, setRandomCustomerProfile1Rating] = useState(5)   
        const [RandomCustomerProfile2Rating, setRandomCustomerProfile2Rating] = useState(5)  
        const [RandomCustomerProfile3Rating, setRandomCustomerProfile3Rating] = useState(5)   
        
        
        const [RandomCustomerProfile1Review, setRandomCustomerProfile1Review] = useState(5)   
        const [RandomCustomerProfile2Review, setRandomCustomerProfile2Review] = useState(5)  
        const [RandomCustomerProfile3Review, setRandomCustomerProfile3Review] = useState(5)   
        

        useEffect(() => {
   
       //   console.log("DMPDCustomerApplause.useEffect[].props: ",props)

          if (props.Page !== undefined) {
            setCommentLink("/LeaveComment?Page=" + props.Page.replaceAll(",", '').replaceAll(" ", ''))
          }

          //console.log("DMPDCustomerApplause.useEffect[].CommentLink: ",CommentLink)
          GetRandomCustomerCommentProfiles()
          GetProductReviews()
        },[]) 

        useEffect(() => {
   
          //console.log("DMPDCustomerApplause.useEffect[RandomCustomerProfile1].RandomCustomerProfile1: ",RandomCustomerProfile1)


        },[RandomCustomerProfile1])         

        
        async function GetRandomCustomerCommentProfiles() {
          try {

                    let vOFJSON = ""

                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/RhyteItCustomerComments.json`

                    const fetchResponse = await fetch(url);
                    let CustomerProfileData1 = await fetchResponse.json();
                    //console.log("GetOnlyFansProspectingProfilesFromS3.CustomerProfileData.length: ",CustomerProfileData1.length);

                    setCustomerProfileData(CustomerProfileData1) 
                    
                    let Index1 = Math.floor(Math.random() * 90);
                    let Index2 = Math.floor(Math.random() * 90);
                    let Index3 = Math.floor(Math.random() * 90);


                    setRandomCustomerProfile1(CustomerProfileData1[Index1])
                    setRandomCustomerProfile2(CustomerProfileData1[Index2])
                    setRandomCustomerProfile3(CustomerProfileData1[Index3])

                

  
          } catch (err) { 
                          console.error('DMPDCustomerApplause.GetRandomCustomerCommentProfiles.ERROR: ', err, err.stack); 
                      }
        }          

        async function GetProductReviews() {
          try {

                    setRandomCustomerProfile1Review([])
                    //console.log("ProductReviewsData2.GetProductReviews.ProductId: ",ProductId);
  
                    const variables = { 
                      filter: {
                                Product: {
                                      eq: ProductId
                                  }
                              }
                    };              
                    
          
                    let ProductReviewsData2  = await client.graphql({
                      query: listProductReviews,
                      variables: variables
                    });

  
                    if (ProductReviewsData2.data.listProductReviews.items.length > 0) {
                      
                        //console.log("ProductReviewsData2.data.listProductReviews.items: ",ProductReviewsData2.data.listProductReviews.items);
                        let ReviewIndex1 = Math.floor(Math.random() * ProductReviewsData2.data.listProductReviews.items.length-1)
                        let ReviewIndex2 = Math.floor(Math.random() * ProductReviewsData2.data.listProductReviews.items.length-1)
                        let ReviewIndex3 = Math.floor(Math.random() * ProductReviewsData2.data.listProductReviews.items.length-1)
                        //console.log("ProductReviewsData2.data.listProductReviews.items.length: ",ProductReviewsData2.data.listProductReviews.items.length);
                        //console.log("ProductReviewsData2.data.listProductReviews.items: ",ProductReviewsData2.data.listProductReviews.items);
                        //console.log("ProductReviewsData2.data.listProductReviews.ReviewIndex1: ", ReviewIndex1);
                        //console.log("ProductReviewsData2.data.listProductReviews.items[ReviewIndex1][Review]: ",ProductReviewsData2.data.listProductReviews.items[ReviewIndex1]["Review"]);
                        setRandomCustomerProfile1Review(ProductReviewsData2.data.listProductReviews.items[ReviewIndex1]["Review"])
                        setRandomCustomerProfile2Review(ProductReviewsData2.data.listProductReviews.items[ReviewIndex2]["Review"])
                        setRandomCustomerProfile3Review(ProductReviewsData2.data.listProductReviews.items[ReviewIndex3]["Review"])

                        setRandomCustomerProfile1Rating(ProductReviewsData2.data.listProductReviews.items[ReviewIndex1]["Rating"])
                        setRandomCustomerProfile2Rating(ProductReviewsData2.data.listProductReviews.items[ReviewIndex2]["Rating"])
                        setRandomCustomerProfile3Rating(ProductReviewsData2.data.listProductReviews.items[ReviewIndex3]["Rating"])
            
                  }
  
          } catch (err) { 
                          console.error('DigitalMarketPlace.GetDigitalMarketPlace.ERROR: ', err, err.stack); 
                      }
          }            
  

    return ( 
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="center"
                  wrap="nowrap"
                  gap="2rem"
                  className={styles.FormContainer}
                >  
                    <Text className={styles.HeaderText}>How our customers feel about {Page}</Text>
                    <Divider className={styles.DividerHeader} padding="xs" /> 
                    <Flex
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="3rem"
                          className={styles.FlexRow} 
                        > 
                      <Rating
                        className={styles.Rating}
                        value={4}
                        maxValue={5}
                        />                      
                    </Flex>            
                    <Flex
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="3rem"
                          className={styles.FlexRowCards} 
                        >                             
                      <div  className={styles.card}>
                        <div  className={styles.content}>
                          <div  className={styles.front}>
                            <Image className={styles.Image} src={RandomCustomerProfile1.OnlyFansProfilePictureURL} />                                                        
                            <Divider className={styles.DividerFront} padding="xs" />                    
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile1.OnlyFansName}</Heading>  
                          </div>
                          <div  className={styles.back}>
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile1.OnlyFansName}</Heading>  
                            <Divider padding="xs" />                    
                            <h1  className={styles.ReviewText} >{RandomCustomerProfile1Review}</h1> 
                            <Rating
                              className={styles.RatingCustomer}
                              value={RandomCustomerProfile1Rating}
                              maxValue={5}
                            />                                                  
                          </div>
                        </div>
                      </div>
                      <div  className={styles.card}>
                        <div  className={styles.content}>
                          <div  className={styles.front}>
                            <Image className={styles.Image} src={RandomCustomerProfile2.OnlyFansProfilePictureURL} />                                                        
                            <Divider className={styles.DividerFront} padding="xs" />                    
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile2.OnlyFansName}</Heading>  
                          </div>
                          <div  className={styles.back}>
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile2.OnlyFansName}</Heading>  
                            <Divider padding="xs" />                    
                            <h1 className={styles.ReviewText} >{RandomCustomerProfile2Review}</h1>                              
                            <Rating
                              className={styles.RatingCustomer}
                              value={RandomCustomerProfile2Rating}
                              maxValue={5}
                              />                                                  
                          </div>
                        </div>
                      </div>     
                      <div  className={styles.card}>
                        <div  className={styles.content}>
                          <div  className={styles.front}>
                            <Image className={styles.Image} src={RandomCustomerProfile3.OnlyFansProfilePictureURL} />                                                        
                            <Divider className={styles.DividerFront} padding="xs" />                    
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile3.OnlyFansName}</Heading>  
                          </div>
                          <div  className={styles.back}>
                            <Heading  className={styles.Heading} padding="medium">{RandomCustomerProfile3.OnlyFansName}</Heading>  
                            <Divider padding="xs" />                    
                            <h1  className={styles.ReviewText} >{RandomCustomerProfile3Review}</h1>
                            <Rating
                              className={styles.RatingCustomer}
                              value={RandomCustomerProfile3Rating}
                              maxValue={5}
                              />                                  
                          </div>
                        </div>
                      </div>                       
                    </Flex>  
                    <Flex
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        gap="1rem"
                        className={styles.FlexRow}
                      >
                      <Link
                            to={CommentLink}
                            isExternal={false}
                            state={{ Page: Page }}
                            >
                      <Button className={styles.Button}>
                            Leave Comment on {Page}
                      </Button>
                      </Link>
                    </Flex>                                             
                </Flex>    

    )

  }
 

export default DMPDCustomerApplause;