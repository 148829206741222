
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  { listNewsletterGenerations }  from '../../../graphql/queries'
  import {
    updateNewsletter,
    deleteNewsletter,
    createNewsletterGeneration
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterPromptTable.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import RhyteItTextField from './RhyteItTextField';  
  import RhyteItTextAreaFieldJSON from './RhyteItTextAreaFieldJSON';  
  
  
  
function NewsletterPromptTable(
  props
) {       

  NewsletterPromptTable.propTypes = {
    Newsletter: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vNewsletterActive, setvNewsletterActive] = useState(false)  
    let [vFieldNameSelected, setvFieldNameSelected] = useState()  
    let [vLabelSelected, setvLabelSelected] = useState()  
    let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 
    let [vNewsletterPrompts, setvNewsletterPrompts] = useState()
    let [vNewsletterGeneration, setvNewsletterGeneration] = useState()

    

    let vFieldButtons =  [
      {"FieldName" : "IntroductionPrompt", "LabelName" : "Introduction Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "WhatsNewPrompt", "LabelName" : "What’s New Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "ComingSoonPrompt", "LabelName" : "Coming Soon Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "CreatorSpotlightPrompt", "LabelName" : "Creator Spotlight Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "NewsCategoryAnalyisPrompt", "LabelName" : "News Category Analyis Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "NewsKeyWordAnalyisPrompt", "LabelName" : "News KeyWord Analyis Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "NewsProNounAnalyisPrompt", "LabelName" : "News ProNoun Analyis Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "TrendingBlogPostTopicPrompt", "LabelName" : "Trending Blog Post Topic Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "QuickTipsPrompt", "LabelName" : "QuickTips Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "IndustryNewsRoundupPrompt", "LabelName" : "Industry News Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "DataInsightsCornerPrompt", "LabelName" : "Data Insights Corner Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "IndustryNewsRoundupPrompt", "LabelName" : "Industry News Roundup Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "PodcastPrompt", "LabelName" : "Podcast Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "CommunityEngagementPrompt", "LabelName" : "Community Engagement Prompt", "FieldType" : "TextAreaField"},
      {"FieldName" : "CallToActionPrompt", "LabelName" : "Call To Action Prompt", "FieldType" : "TextAreaField"},
    ]
        
      useEffect(() => {

        //console.log("NewsletterPromptTable.useEffect[].props.Newsletter:  ",props.Newsletter) 

      },[])  

      useEffect(() => {
      //console.log("NewsletterPromptTable.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("NewsletterPromptTable.useEffect[vNewsletter].vNewsletter: " ,vNewsletter);

        if (vNewsletter !== undefined) {
          GetNewsletterPrompts()
        }

      }, [vNewsletter]);   
      
      useEffect(() => {

 
         if (vNewsletterPrompts !== undefined) {
          //console.log("NewsletterPromptTable.useEffect[vNewsletterPrompt].vNewsletterPrompt: " ,vNewsletterPrompt);
         }
 
       }, [vNewsletterPrompts]);   

       useEffect(() => {

 
        if (vNewsletterGeneration !== undefined) {

         let  vOpenAIPrompts = {
          }
         
          if ("OpenAIPrompts" in vNewsletterGeneration) {

            const vPromptsJSON = JSON.parse(vNewsletterGeneration["OpenAIPrompts"]);  
            
            if (vPromptsJSON !== null) {     

                if ("IntroductionPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["IntroductionPrompt"] = vPromptsJSON["IntroductionPrompt"]
                }            
                if ("WhatsNewPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["WhatsNewPrompt"] = vPromptsJSON["WhatsNewPrompt"]
                }        
                if ("ComingSoonPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["ComingSoonPrompt"] = vPromptsJSON["ComingSoonPrompt"]
                }        
                if ("CreatorSpotlightPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["CreatorSpotlightPrompt"] = vPromptsJSON["CreatorSpotlightPrompt"]
                }        
                if ("NewsCategoryAnalyisPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["NewsCategoryAnalyisPrompt"] = vPromptsJSON["NewsCategoryAnalyisPrompt"]
                }        
                if ("NewsKeyWordAnalyisPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["NewsKeyWordAnalyisPrompt"] = vPromptsJSON["NewsKeyWordAnalyisPrompt"]
                }        
                if ("NewsProNounAnalyisPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["NewsProNounAnalyisPrompt"] = vPromptsJSON["NewsProNounAnalyisPrompt"]
                }            
                if ("TrendingBlogPostTopicPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["TrendingBlogPostTopicPrompt"] = vPromptsJSON["TrendingBlogPostTopicPrompt"]
                }        
                if ("QuickTipsPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["QuickTipsPrompt"] = vPromptsJSON["QuickTipsPrompt"]
                }            
                if ("IndustryNewsRoundupPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["IndustryNewsRoundupPrompt"] = vPromptsJSON["IndustryNewsRoundupPrompt"]
                }            
                if ("DataInsightsCornerPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["DataInsightsCornerPrompt"] = vPromptsJSON["DataInsightsCornerPrompt"]
                }
                if ("PodcastPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["PodcastPrompt"] = vPromptsJSON["PodcastPrompt"]
                }
                if ("CommunityEngagementPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["CommunityEngagementPrompt"] = vPromptsJSON["CommunityEngagementPrompt"]
                }
                if ("CallToActionPrompt" in vPromptsJSON) {
                  vOpenAIPrompts["CallToActionPrompt"] = vPromptsJSON["CallToActionPrompt"]
                }

              } 

          }

          setvNewsletterPrompts(vOpenAIPrompts)

        }



      }, [vNewsletterGeneration]);   

      useEffect(() => {
        //console.log("NewsletterPromptTable.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("NewsletterPromptTable.ExpandRow.FieldName: " ,pExpandRow["FieldName"]);
        //console.log("NewsletterPromptTable.ExpandRow.LabelName: " ,pExpandRow["LabelName"]);
        //console.log("NewsletterPromptTable.ExpandRow.FieldType: " ,pExpandRow["FieldType"]);
        //console.log("NewsletterPromptTable.ExpandRow.vNewsletter);: " ,vNewsletter);
        setvFieldNameSelected(pExpandRow["FieldName"])
        setvLabelSelected(pExpandRow["LabelName"])
        setvFieldTypeSelected(pExpandRow["FieldType"])


          } catch (err) { 

          ErrorMessageStr = "NewsletterPromptTable.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     
     
      async function GetNewsletterPrompts() {
        try {
          setErrorMessage("");
          
          //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
          let variables;
          if (Users !== "edwardhawke@rhyteit.com") {
            variables = {
              filter: {
                Newsletter: {
                  eq: vNewsletter["Newsletter"],
                },
                AccountGroup: {
                  eq: vNewsletter["AccountGroup"],
                },
              },
            };
          } else {
            variables = {};
          }
    
          const listNewsletterGenerationData2 = await client.graphql({
            query: listNewsletterGenerations,
            variables: variables,
          });
    
          //console.log("NewsletterPromptTable.GetNewsletterPrompts.listNewsletterGeneration.data.listNewsletterGeneration.items.length : ", listNewsletterGenerationData2.data.listNewsletterGenerations.items.length );
    
          if (listNewsletterGenerationData2.data.listNewsletterGenerations.items.length > 0) {
            const vNewsletterGeneration =
              listNewsletterGenerationData2.data.listNewsletterGenerations.items[0];
              setvNewsletterGeneration(vNewsletterGeneration)
          } 
    
    
    
        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterPromptTable",
            "GetNewsletterPrompts",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }


      return (
        <TableBody>         
          <TableRow  className={styles.TableRowActive}  key={vNewsletter.CreationDateTime}>     
          <TableCell className={styles.TableCellLeftAlign} colspan="6"> 
          <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="wrap"
               gap="1rem"
               className={styles.FlexRow}>   
          {
            vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (                      
              (<Button   key={vFieldButton.FieldName}  
                          className={styles.ButtonSection}
                          onClick={(e) => ExpandRow(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
              ))
          }            
                          
          </Flex>                     
                </TableCell>                  
              </TableRow>                           
                

          {
            vFieldTypeSelected === "TextField" &&  (                     
              <RhyteItTextField  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterGeneration={vNewsletterGeneration} NewsletterPrompts={vNewsletterPrompts}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterPrompts[vFieldNameSelected]}/>
            )          
          }
          {
            vFieldTypeSelected === "TextAreaField" &&  (                     
              <RhyteItTextAreaFieldJSON  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterGeneration={vNewsletterGeneration} NewsletterPrompts={vNewsletterPrompts}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterPrompts[vFieldNameSelected]}/>
            )          
          }
              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterPromptTable;

