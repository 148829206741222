import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Divider,
  Image,
  Link
  } from '@aws-amplify/ui-react';
  import styles from './FooterV2.module.css';  

const FooterV2 = (props) => {

  FooterV2.propTypes = {
      pageSource: PropTypes.string
    };




useEffect(() => {
 

     
          
},[])        



// onMouseLeave={ (e) => ActionsContainerHoverAction(e, false) } 

  return (
    <div  className={styles.Footer}>      
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="3rem"
          className={styles.TopFlexRow}
        >            
          <Flex
            className={styles.FlexColumnInfo}
          >  
              <a  className={styles.FooterSMLink} href="mailto:support@rhyteit.com">support@rhyteit.com</a>
              <span className={styles.FooterSMLink} id="copyrightYear">©2025 RhyteIt</span>
          </Flex>                
          <Flex
            direction="column"
            className={styles.FlexColumnSM}
          >             
            <Flex
              direction="row"
              justifyContent="right"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className={styles.FlexRowSM}
            >             
                <Link className={styles.Link} href="https://medium.com/@support_13571" isExternal={true}>  
                  <Text className={styles.FooterSMLink}    
                      title="Medium">Md</Text>            
                </Link>   
                <span className={styles.Dots}> • </span>
                <Link className={styles.Link} href="https://twitter.com/RhyteIt" isExternal={true}>  
                  <Text className={styles.FooterSMLink}    
                      title="X/Twitter">Tw</Text>            
                </Link>   
                <span className={styles.Dots}> • </span>
                <Link className={styles.Link} href="https://www.instagram.com/rhyteit/" isExternal={true}>  
                  <Text className={styles.FooterSMLink}    
                      title="Instagram">Ig</Text>            
                </Link>   
                <span className={styles.Dots}> • </span>
                <Link className={styles.Link} href="https://discord.gg/Q4EbGsvNzA" isExternal={true}>  
                  <Text className={styles.FooterSMLink}    
                      title="Discord">Di</Text>            
                </Link>   
            </Flex>          
            <Flex
              direction="row"
              justifyContent="right"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className={styles.FlexRowSM}
            >               
                <Link className={styles.Link} href="/PrivacyPolicy" isExternal={false}>  
                    <Text className={styles.FooterSMLink}    
                        variation="primary">Privacy</Text>
                    
                </Link> 
                <span className={styles.Dots}> • </span>
            </Flex>  
          </Flex>      
        </Flex>
      <div className={styles.space}/>
     </div>
  )
}

export default FooterV2;