import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Header from "../../HeaderMenu/LandingPageNavBar";
import { listProducts } from "../../../graphql/queries";
import { createAudienceGeoInfo } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import { motion as m } from "framer-motion";
import getStripe from "../../Stripe/getStripe";
import DMPDCustomerApplause from "../DMPDCustomerApplause";
import Footer from "../../RhyteItComponents/Footer/Footer";
import { uuidv4 } from "../../../BusinessLogic/UtilitiesManagement";

import { Button, Flex, TextField, Image, Text,Divider } from "@aws-amplify/ui-react";
import styles from "./TurboFanBoost.module.css";
import { SEO } from "../../SEO/SEO";
import {
  
  RhyteItLogging,
} from "../../../BusinessLogic/UserManagement";

function ErrorFallback({ error, resetErrorBoundary }) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string,
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const TurboFanBoost = () => {
  const client = generateClient();

  let debugConsoleOutPut = true;
  var vFirstTouch = true;

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);
  const [RecordUpdated, setRecordUpdated] = useState(false);
  const [UserEmail, setUserEmail] = useState("");

  const [InvalidEmail, setInvalidEmail] = useState(false);

  const [RhyteItProduct, setRhyteItProduct] = useState();
  const [Environment, setEnvironment] = React.useState(
    process.env.REACT_APP_ENV
  );
  const [PaymentLine, setPaymentLine] = useState("");
  const [DescriptionHtml, setDescriptionHtml] = useState("");
  const [ThankYou, setThankYou] = useState(false);

  useEffect(() => {
    //  console.log("TurboFanBoost.useEffect[].RhyteItProduct:  ",RhyteItProduct);

    (async function () {
      try {

        window.scrollTo(0, 0);
        getData();
        GetTurboFanBoost();
      } catch (err) {
        console.error("RhyteItProducts.useEffect[].ERROR: ", err, err.stack);
      }
    })();
  }, []);

  useEffect(() => {
    //     console.log("TurboFanBoost.useEffect[RhyteItProduct].RhyteItProduct:  ",RhyteItProduct);

    if (RhyteItProduct !== undefined) {
      let vPaymentDescription =
        "Monthly subscription of $" +
        RhyteItProduct.Price +
        " (Cancel Anytime)";

      setPaymentLine(vPaymentDescription);

      Dashboard(RhyteItProduct.Description);
    }
  }, [RhyteItProduct]);

  async function GetTurboFanBoost() {
    try {
      setErrorMessage("");

      //       console.log("TurboFanBoost.GetTurboFanBoost.Start")

      let vTurbFanBoost = "";

      if (Environment === "TEST") {
        vTurbFanBoost = "TurboFanBoost Test";
      } else {
        vTurbFanBoost = "TurboFanBoost";
      }

      const variables = {
        filter: {
          Product: {
            eq: vTurbFanBoost,
          },
        },
      };

      let TurboFanBoostData2 = await client.graphql({
        query: listProducts,
        variables: variables,
      });

      //       console.log("TurboFanBoost.GetTurboFanBoost.TurboFanBoostData2.data.listProducts.items:  ",TurboFanBoostData2.data.listProducts.items)

      if (TurboFanBoostData2.data.listProducts.items.length > 0) {
        setRhyteItProduct(TurboFanBoostData2.data.listProducts.items[0]);
      }
    } catch (err) {
      console.error("TurboFanBoost.GetTurboFanBoost.ERROR: ", err, err.stack);
    }
  }

  async function handleCheckout() {
    //console.log("TurboFanBoost.handleCheckout.UserEmail: ",UserEmail)
    //console.log("TurboFanBoost.handleCheckout.RhyteItProduct.PaymentMode: ",RhyteItProduct.PaymentMode)

    if (UserEmail !== "") {
      if (
        UserEmail.indexOf("@") > 2 &&
        UserEmail.indexOf(".") > UserEmail.indexOf("@") + 1 &&
        UserEmail.indexOf(" ") < 0
      ) {
        setInvalidEmail(false);
        setThankYou(true);
        window.scrollTo(0, 0);

        const stripe = await getStripe(Environment);

        //console.log("TurboFanBoost.handleCheckout.stripe: ",stripe)
        //console.log("TurboFanBoost.handleCheckout.PriceId:  ",RhyteItProduct.PriceId)

        if (stripe !== "ERROR") {
          const { error } = await stripe.redirectToCheckout({
            lineItems: [
              {
                price: RhyteItProduct.PriceId,
                quantity: 1,
              },
            ],
            mode: RhyteItProduct.PaymentMode,
            successUrl: RhyteItProduct.SuccessURL,
            cancelUrl: RhyteItProduct.CancelURL,
            customerEmail: UserEmail.replaceAll(" ", ""),
          });

          console.warn(error.message);
        } else {
          ErrorMessageStr =
            "Something went wrong with checkout process. Its not you. Its us. Sigh....";
          setErrorMessage(ErrorMessageStr);
        }
      }
    } else {
      setInvalidEmail(true);
      let ErrorMessageStr = "Please enter a valid email";
      setUserEmail(ErrorMessageStr);
    }
  }

  function UserEmail_handleChange(event, data) {
    try {
      if (debugConsoleOutPut === true) {
        //console.log("AdultContentCreatorSearch.FindField_handleChange.event.target.value: ",event.target.value)
      }

      var ErrorMessageStr = "";
      setErrorMessage(ErrorMessageStr);
      setUserEmail(event.target.value);
    } catch (err) {
      console.error(
        "AdultContentCreatorSearch.FindField_handleChange.Error:  ".err,
        err.stack
      );
    }
  }

  const Dashboard = (pDescription) => {
    let DescriptionArray = pDescription.split("\\n");

    let DescriptionList = [];

    DescriptionArray.forEach((Description, index) => {
      let vDescription = Description.indexOf("<br>");

      if (vDescription > -1) {
        DescriptionList.push(<br />);
      } else {
        DescriptionList.push(
          <div className={styles.TextAreaField}>{Description}</div>
        );
      }
    });

    //console.log("TurboFanBoost.TurboFanBoost.DescriptionList: ",DescriptionList[0]["props"]['children'])

    let DescriptionHtmlString = <div>{DescriptionList}</div>;

    setDescriptionHtml(DescriptionHtmlString);
  };

  const getData = async () => {
    
    
    if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
      //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
      vFirstTouch = false;

      let DynamoUUID = await uuidv4();

      let AudienceGeoItem = {
        id: DynamoUUID,
        UserId: "NA",
        Page: "TurboFanBoost",
        IPv4: "000.00.00.000",
        city: "NA",
        country_code:"NA",
        country_name: "NA",
        latitude: "00.000",
        longitude: "00.000",
        postal: "NA",
        state: "NA",
        CreationName: "TurboFanBoost",
        UpdateName: "TurboFanBoost",
      };

      //console.log("LandingPageNavBar.getData.AudienceGeoItem: " ,AudienceGeoItem)

      try {
        let createAudienceGeoInfoResponse = await client.graphql({
          query: createAudienceGeoInfo,
          variables: {
            input: AudienceGeoItem,
          },
        });
      } catch (err) {
        RhyteItLogging(
          "NA",
          "LandingPageV5",
          "getData",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  };  

  return (
    <m.div
      key="RhyteItTurboFanBoost"
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className={styles.Background}
    >
      <SEO
        title="TurbFanBoost"
        description="Post to your social accounts while you sleep"
        name="RhyteIt"
        type="product"
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {RhyteItProduct !== undefined && (
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="wrap"
            className={styles.GridContainer}
          >
            <div className={styles.Space}></div>
            <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.ProductImageContainer}
                >
            <Image
              className={styles.ProductImage}
              src={RhyteItProduct.ProductImage}
              objectFit="initial"
              backgroundColor="initial"
              onClick={() => alert("📸 Buy Now!")}
            />
            </Flex>

            <Text className={styles.ProductName}>{RhyteItProduct.Product}</Text>

            {ThankYou === false && (
              <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
              >
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.FlexRow}
                >
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                  >
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRow}
                    >
                      <Text className={styles.HeroSectionHeadline}>
                        💥 Dominate Social Media. Make Headlines. Only
                        $10/Month.
                      </Text>
                    </Flex>
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.HeroFlexRow}
                    >
                      <Text className={styles.HeroSectionSubheadline}>
                        Stop wasting time juggling posts and struggling to stand
                        out. TurboFanBoost gives you non-stop content
                        distribution across X/Twitter, Reddit, and BlueSky, PLUS
                        a professional press release every week published to
                        Google News.
                      </Text>
                    </Flex>
                    {RhyteItProduct.PaymentMode !== "free" && (
                      <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        gap="1rem"
                        className={styles.FlexRow}
                      >
                        <Text className={styles.TextFieldLabelThin}>
                          Price ($USD):
                        </Text>
                        <Text className={styles.TextFieldThin}>
                          {PaymentLine}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRow}
                    >
                      <Text className={styles.TextFieldLabelThin}>Email:</Text>
                      <TextField
                        label="Search"
                        labelHidden={true}
                        className={
                          InvalidEmail === false
                            ? styles.TextField
                            : styles.InvalidEmailField
                        }
                        defaultValue={UserEmail}
                        value={UserEmail}
                        placeholder="<enter email here>"
                        onChange={(e) => UserEmail_handleChange(e, UserEmail)}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.FlexRow}
                >
                  <Button
                    className={styles.metallicbutton}
                    onClick={(e) => handleCheckout(e, RhyteItProduct)}
                    isDisabled={ThankYou}
                    variation="primary"
                  >
                    {ThankYou === false
                      ? RhyteItProduct.PaymentMode === "free"
                        ? "Free"
                        : "👉 Boost Your Fans Now for Just $10/Month"
                      : "Enjoy!"}
                  </Button>
                  {RhyteItProduct.PaymentMode !== "free" && (
                    <Image
                      className={styles.Image}
                      src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                      objectFit="initial"
                      backgroundColor="initial"
                      onClick={() => alert("📸 BUY NOW!")}
                    />
                  )}
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.FlexRow}
                >
                  <div className={styles.MessageBox}>{ErrorMessage}</div>
                </Flex>
                <Divider className={styles.DividerHeader} label="Key Benefits" size="large" /> 
                <Text className={styles.HeroSectionHeadline}>
                  🎯 What You Get with TurboFanBoost
                </Text>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                    🚀 Non-Stop Posting: We manage your content 24/7 across
                    X/Twitter, Reddit, and BlueSky, so you stay visible even
                    while you sleep.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                    📰 Professional Press Releases: Get one high-quality,
                    professional press release each week to showcase your
                    updates and achievements.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                    🔍 Google News Exposure: Your press releases are published
                    to Google News, putting you on the same playing field as top
                    creators.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                    📈 Fan Growth Simplified: With automated social media and
                    professional press coverage, you’ll attract more fans and
                    impress your current ones.
                  </Text>
                </Flex>
                <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRow}
                    >
                      <Text className={styles.TextFieldLabelThin}>Email:</Text>
                      <TextField
                        label="Search"
                        labelHidden={true}
                        className={
                          InvalidEmail === false
                            ? styles.TextField
                            : styles.InvalidEmailField
                        }
                        defaultValue={UserEmail}
                        value={UserEmail}
                        placeholder="<enter email here>"
                        onChange={(e) => UserEmail_handleChange(e, UserEmail)}
                      />
                </Flex> 
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.FlexRow}
                >
                  <Button
                    className={styles.metallicbutton}
                    onClick={(e) => handleCheckout(e, RhyteItProduct)}
                    isDisabled={ThankYou}
                    variation="primary"
                  >
                    {ThankYou === false
                      ? RhyteItProduct.PaymentMode === "free"
                        ? "Free"
                        : "✨ Start TurboBoosting Your Fans Today"
                      : "Enjoy!"}
                  </Button>
                  {RhyteItProduct.PaymentMode !== "free" && (
                    <Image
                      className={styles.Image}
                      src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                      objectFit="initial"
                      backgroundColor="initial"
                      onClick={() => alert("📸 BUY NOW!")}
                    />
                  )}
                </Flex>
                <Divider className={styles.DividerHeader} label="Why It Matters" size="large" /> 
                <Text className={styles.HeroSectionHeadline}>
                📢 The Secret of Top Creators
                </Text>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  The most successful creators don’t just post—they dominate the headlines.
                  They use tools to automate their content, share newsworthy updates, and showcase their credibility to fans and prospects.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  With TurboFanBoost, you can do the same:
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  Save hours of your time with automated social media management.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  Build trust and authority by sharing press releases with your audience.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  Get featured on Google News to grow your reputation and stand out.
                  </Text>
                </Flex>
                <Divider className={styles.DividerHeader} label="Pricing" size="large" /> 
                <Text className={styles.HeroSectionHeadline}>
                💸 All This for Just $10/Month
                </Text>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  For less than the price of your morning coffee, TurboFanBoost provides:
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  24/7 social media automation.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  Weekly professional press releases.
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Text className={styles.HeroSectionSubheadline}>
                  Google News exposure.
                  </Text>
                </Flex>
                <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRow}
                    >
                      <Text className={styles.TextFieldLabelThin}>Email:</Text>
                      <TextField
                        label="Search"
                        labelHidden={true}
                        className={
                          InvalidEmail === false
                            ? styles.TextField
                            : styles.InvalidEmailField
                        }
                        defaultValue={UserEmail}
                        value={UserEmail}
                        placeholder="<enter email here>"
                        onChange={(e) => UserEmail_handleChange(e, UserEmail)}
                      />
                </Flex> 
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.FlexRow}
                >
                  <Button
                    className={styles.metallicbutton}
                    onClick={(e) => handleCheckout(e, RhyteItProduct)}
                    isDisabled={ThankYou}
                    variation="primary"
                  >
                    {ThankYou === false
                      ? RhyteItProduct.PaymentMode === "free"
                        ? "Free"
                        : "👉 Get Started for Just $10/Month"
                      : "Enjoy!"}
                  </Button>
                  {RhyteItProduct.PaymentMode !== "free" && (
                    <Image
                      className={styles.Image}
                      src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                      objectFit="initial"
                      backgroundColor="initial"
                      onClick={() => alert("📸 BUY NOW!")}
                    />
                  )}
                </Flex>     
                <Divider className={styles.DividerHeader} label="Social Proof" size="large" />                                   
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <DMPDCustomerApplause
                    Page={RhyteItProduct.Product}
                    ProductId={RhyteItProduct.id}
                  />
                </Flex>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.HeroFlexRow}
                >
                  <Footer />
                </Flex>
              </Flex>
            )}
            {ThankYou === true && (
              <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
              >
                <Text className={styles.CongratulationsText}>
                  Time to get you subscribers!! :)
                </Text>
                <Text className={styles.PleaseWaitText}>Please wait!</Text>
                <Text className={styles.NextStepText}>
                  ...while we transfer you to Stripe for payment processing.
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <Image
          alt="Amplify logo"
          src="/images/bee.gif"
          className={styles.HobnilTheBee}
          onClick={() => alert("📸 Say cheese!")}
        />
      </ErrorBoundary>
      )
    </m.div>
  );
};

export default TurboFanBoost;
