
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  TableRow,
  Image,
  Button,
  Flex
  } from '@aws-amplify/ui-react'; 
import PodcastEpisode from './PodcastEpisode';
import { } from '../../graphql/queries'
import {GetRSSFeedJSON}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './PodcastEpisodes.module.css';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';


const PodcastEpisodes = (props) => {       

  PodcastEpisodes.propTypes = {
      Episodes: PropTypes.array,
      Show: PropTypes.object,
      };

    let debugConsoleOutPut = true
  

    const [vEpisodes, setvEpisodes] = useState()    
    const [ShowTable, setShowTable] = useState(false); 
    const [vShow, setvShow] = useState()
    const [vHostEmail, setvHostEmail] = useState()
    const [vHostEmailString, setvHostEmailString] = useState()
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [vEpisodesRefresh, setvEpisodesRefresh] = useState()  


    useEffect(() => {

      //console.log("PodcastEpisodes.useEffect[].props.Show:  ",props.Show)
      GetRSSFeedJSONv2()

    },[])  

    useEffect(() => {
      //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
   
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);


    useEffect(() => {

     //console.log("PodcastEpisodes.useEffect[vEpisodes].vEpisodes:  ",vEpisodes)
      //console.log("PodcastEpisodes.useEffect[].vEpisodes ",vEpisodes) 
      //console.log("vPodcastEpisodes.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vPodcastEpisodes.useEffect[].Time:  ", PodcastEpisode.PostDateTime.substr(PodcastEpisode.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vPodcastEpisodes.useEffect[].indexOf:  ", PodcastEpisode.PostDateTime.indexOf(":00"))
      if (vEpisodes !== undefined) {
        if (vEpisodes.length > 0) {
          setShowTable(true)        
          setvUpdateKey(Date.now())
          setvEpisodesRefresh(Date.now)
          }
      }

    },[vEpisodes])  

    useEffect(() => {

      //console.log("PodcastEpisodes.useEffect[vEpisodes].vEpisodes:  ",vEpisodes)
       //console.log("PodcastEpisodes.useEffect[].vEpisodes ",vEpisodes) 
       //console.log("vPodcastEpisodes.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
       //console.log("vPodcastEpisodes.useEffect[].Time:  ", PodcastEpisode.PostDateTime.substr(PodcastEpisode.PostDateTime.indexOf("T")+1, 5))
       //onsole.log("vPodcastEpisodes.useEffect[].indexOf:  ", PodcastEpisode.PostDateTime.indexOf(":00"))
       if (vShow !== undefined) {
         if (vShow.length > 0) {
           setvHostEmail(vShow["HostEmail"])
           }
       }
 
     },[vShow])  
 

    
    
    useEffect(() => {
      //console.log("PodcastEpisodes.useEffect[vHostEmail].vHostEmail:  ",vHostEmail)

      if (vHostEmail !== undefined) {

      let vHostEmailStringTmp = "mailto:"  
        vHostEmailStringTmp = vHostEmailStringTmp.concat(vHostEmail);
        vHostEmailStringTmp = vHostEmailStringTmp.concat("?subject=I%20love%20your%20show%20but...");
        //console.log("PodcastEpisodes.useEffect[vHostEmail].vHostEmailString:  ",vHostEmailString)
        setvHostEmailString(vHostEmailStringTmp)
        setvUpdateKey(Date.now())
       }
 
     },[vHostEmail])     

    
     useEffect(() => {

      if (vHostEmailString !== undefined) {

        
      //console.log("PodcastEpisodes.useEffect[vHostEmailString].vHostEmailString:  ",vHostEmailString)
        
       }
 
     },[vHostEmailString])     


     async function GetRSSFeedJSONv2() {
      try {         
  
        let variables = {
          Podcast: "Adult Content Mastery"
        }

        let GetRSSFeedJSONResponse = await client.graphql({
          query: GetRSSFeedJSON,
          variables: {
            input: variables
          }
        });        


        //console.log("Podcast.GetRSSFeedJSON.GetRSSFeedJSONResponse: ",GetRSSFeedJSONResponse)

        let RssXMLJSON = JSON.parse(GetRSSFeedJSONResponse["data"]["GetRSSFeedJSON"]);      

        //console.log("Podcast.GetRSSFeedJSON.RssXMLJSON: ",RssXMLJSON)

        let RssXMLJSON2 = JSON.parse(RssXMLJSON["body"]);      

        //console.log("Podcast.GetRSSFeedJSON.RssXMLJSON2: ",RssXMLJSON2)

        setvEpisodes(RssXMLJSON2["Episodes"])
        setvShow(RssXMLJSON2)


      } catch (err) {
  
        
        console.error(
          "PodcastEpisodes.GetS3Objects.ERROR: ",
          err,
          err.stack
        );
  
        RhyteItLogging(
          "NA",
          "PodcastEpisodes",
          "GetS3Objects",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
           

     async function OpenRSSFeed(event,pFile) {
      try {

        //console.log("PodcastEpisodes.OpenRSSFeed.pFile: " ,pFile);

        window.open(pFile, '_blank', 'noopener,noreferrer');
      
          } catch (err) { 

          ErrorMessageStr = "PodcastEpisodes.OpenRSSFeed.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)      

        }               
      
    }     


  return (   
    <section ref={ref} className = {styles.Section}>
      
    
  {vUpdateKey !== undefined &&  (    
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="wrap"
            gap="1rem"
            className = {styles.ShowRowFlex}>
            <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.FlexLeftColumn}>           
                <Image
                    alt=""
                    src={vShow.Image}  
                    objectFit="initial"
                    objectPosition="50% 50%"
                    className={styles.ImageActive}
                    //onMouseEnter={(e) => Alert(e)}
                  />                                                                                                   
            </Flex>                       
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="flex-start"
              wrap="wrap"
              gap="1rem"
              className = {styles.FlexRightColumn}>    
                <motion.div 
                      className = {styles.PodcastTitleDiv}
                      style={{
                          transform: isInView ? "none" : "translateX(-200px)",
                          opacity: isInView ? 1 : 0,
                          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        }}> 
                    <motion.h1  className = {styles.PodcastTitle}>
                      {vShow["Title"]}
                    </motion.h1>   
                </motion.div>
                <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className = {styles.HostRowFlex}>  
                  
                      <motion.div 
                            style={{
                                transform: isInView ? "none" : "translateX(-200px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                              }}> 
                          <motion.h1  className = {styles.Host}>Host: {vShow["Host"]} 
                          </motion.h1>  
                              
                      </motion.div>   
                      {
                        vHostEmailString !== undefined && (                        
                      <a key={vUpdateKey} className={styles.ButtonLinkText} href={vHostEmailString}>                            
                            <Button className = {styles.Button}    >   
                              Email Me                  
                            </Button> 
                      </a>       
                      )
                      }           
                </Flex>                
                <motion.div 
                      className = {styles.PodcastDescriptionDiv}
                      style={{
                          transform: isInView ? "none" : "translateX(-200px)",
                          opacity: isInView ? 1 : 0,
                          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        }}> 
                    <motion.h1  className = {styles.PodcastDescription}>
                      {vShow["Description"]}
                    </motion.h1>   
                </motion.div> 
                <div className = {styles.RSSFeedTextDiv}>            
                <Text className = {styles.RSSFeedText}                    
                  onClick={(e) => OpenRSSFeed(e, vShow["RSSURL"])}   >   
                    RSS Feed                  
                  </Text>   
                  </div>                     
            </Flex>             
        </Flex>          
  )}      
   {vUpdateKey !== undefined &&  (       
        <motion.div 
              className = {styles.EpisodesDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.Episodes}>
              Episodes
            </motion.h1>   
        </motion.div>        
  )}    
    {vUpdateKey !== undefined &&  (         
        <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                gap="1rem"
              >      
                  
                      {
                        vUpdateKey && ShowTable && vEpisodes.map((vPodcastEpisode) => {                      
                        return (<PodcastEpisode   key={vPodcastEpisode.Date}    
                                                PodcastEpisode={vPodcastEpisode} 
                              />)       
                        } 
                          )
                      }                    
        </Flex>           
      )}    
    </section>
  )
}

export default PodcastEpisodes;


