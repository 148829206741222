
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  { listNewsletterGenerations }  from '../../../graphql/queries'
  import {
    updateNewsletter,
    deleteNewsletter,
    createNewsletterGeneration
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterFileURLTable.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import RhyteItTextField from './RhyteItTextField';  
  import RhyteItTextAreaFieldJSON from './RhyteItTextAreaFieldJSON';  
  
  
  
function NewsletterFileURLTable(
  props
) {       

  NewsletterFileURLTable.propTypes = {
    Newsletter: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vNewsletterActive, setvNewsletterActive] = useState(false)  
    let [vFieldNameSelected, setvFieldNameSelected] = useState()  
    let [vLabelSelected, setvLabelSelected] = useState()  
    let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 
    let [vNewsletterFileURLs, setvNewsletterFileURLs] = useState()
    let [vNewsletterGeneration, setvNewsletterGeneration] = useState()

    

    let vFieldButtons =  [
      {"FieldName" : "PodcastFeedXML", "LabelName" : "Podcast feed.xml", "FieldType" : "TextField"},
      {"FieldName" : "NewsCategories", "LabelName" : "News Categories", "FieldType" : "TextField"},
      {"FieldName" : "BlogCategories", "LabelName" : "Blog Categories", "FieldType" : "TextField"}
    ]
        
      useEffect(() => {

        console.log("NewsletterFileURLTable.useEffect[].props.Newsletter:  ",props.Newsletter) 

      },[])  

      useEffect(() => {
      //console.log("NewsletterFileURLTable.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      


      
      useEffect(() => {

 
         if (vNewsletterFileURLs !== undefined) {
          console.log("NewsletterFileURLTable.useEffect[vNewsletterFileURLs].vNewsletterFileURLs: " ,vNewsletterFileURLs);
         }
 
       }, [vNewsletterFileURLs]);   

       useEffect(() => {

 
        if (vNewsletter !== undefined) {

         let  vFileURLs = {
          }
         
          if ("FileURLs" in vNewsletter) {

            const vFileURLsJSON = JSON.parse(vNewsletter["FileURLs"]);  
            
            if (vFileURLsJSON !== null) {     

                if ("PodcastFeedXML" in vFileURLsJSON) {
                  vFileURLs["PodcastFeedXML"] = vFileURLsJSON["PodcastFeedXML"]
                } else {
                  vFileURLs["PodcastFeedXML"] = ""
                } 

                if ("NewsCategories" in vFileURLsJSON) {
                  vFileURLs["NewsCategories"] = vFileURLsJSON["NewsCategories"]
                } else {
                  vFileURLs["NewsCategories"] = ""
                } 
       
                if ("BlogCategories" in vFileURLsJSON) {
                  vFileURLs["BlogCategories"] = vFileURLsJSON["BlogCategories"]
                } else {
                  vFileURLs["BlogCategories"] = ""
                } 
 

              } 

          } else {

            vFileURLs["PodcastFeedXML"] = ""
            vFileURLs["NewsCategories"] = ""
            vFileURLs["BlogCategories"] = ""

          }

          setvNewsletterFileURLs(vFileURLs)

        }



      }, [vNewsletter]);   

      useEffect(() => {
        //console.log("NewsletterFileURLTable.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        setvFieldNameSelected(pExpandRow["FieldName"])
        setvLabelSelected(pExpandRow["LabelName"])
        setvFieldTypeSelected(pExpandRow["FieldType"])


          } catch (err) { 

          ErrorMessageStr = "NewsletterFileURLTable.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     

      return (
        <TableBody>         
          <TableRow  className={styles.TableRowActive}  key={vNewsletter.CreationDateTime}>     
          <TableCell className={styles.TableCellLeftAlign} colspan="6"> 
          <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="wrap"
               gap="1rem"
               className={styles.FlexRow}>   
          {
            vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (                      
              (<Button   key={vFieldButton.FieldName}  
                          className={styles.ButtonSection}
                          onClick={(e) => ExpandRow(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
              ))
          }            
                          
          </Flex>                     
                </TableCell>                  
              </TableRow>                           
                

          {
            vFieldTypeSelected === "TextField" &&  (                     
              <RhyteItTextField  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterFileURLs={vNewsletterFileURLs}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterFileURLs[vFieldNameSelected]}/>
            )          
          }
          {
            vFieldTypeSelected === "TextAreaField" &&  (                     
              <RhyteItTextAreaFieldJSON  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterFileURLs={vNewsletterFileURLs}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterFileURLs[vFieldNameSelected]}/>
            )          
          }
              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterFileURLTable;

