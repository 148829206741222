
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Text,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import EmailHTMLTemplateTable from './EmailHTMLTemplateTable';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './EmailHTMLTemplate.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  getNewsletter,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createNewsletterPrompts,
  deleteNewsletterPrompts
} from "../../../graphql/mutations";


const EmailHTMLTemplate = (props) => {       

  EmailHTMLTemplate.propTypes = {
      Newsletter: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)   
    const [Users, setUsers] = useState(props.Users)   
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vNewsletterPrompts, setvNewsletterPrompts] = useState()   
    const [vKey, setvKey] = useState()   


    

    useEffect(() => {
      //console.log("EmailHTMLTemplate.useEffect[isInView].props.Newsletter: " ,props.Newsletter);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);



    useEffect(() => {

    }, [vNewsletter]);

    async function GetNewsletter() {
      try {
        setErrorMessage("");
        //console.log("NewsletterCategoryPostHeader.getNewsletter.vNewsletterPost: ", vNewsletterPost);
        let variables = {
          id: vNewsletter["id"]
          };
  
        const getNewsletterData = await client.graphql({
          query: getNewsletter,
          variables: variables,
        });

        if (getNewsletterData.data.getNewsletter !== undefined) {
  
          //console.log("EmailHTMLTemplate.getNewsletter.getNewsletter.data.getNewsletter.items: ", getNewsletterData.data.getNewsletter);
          const vNewsletterTmp =
          getNewsletterData.data.getNewsletter;
          setvNewsletter(vNewsletterTmp)
          setvKey(Date.now());
        } 
  
  
  
      } catch (err) {

        console.error("EmailHTMLTemplate.getNewsletter.Error.message:  ", err); 
        console.error("EmailHTMLTemplate.getNewsletter.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "EmailHTMLTemplate",
          "getNewsletter",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );  
      }
    }         
          

  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vNewsletter !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>               
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}>  
               <Text className={styles.SectionTitle}>Email HTML Template</Text>            
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetNewsletter(e)}
              >
                Refresh
            </Button>   
        </Flex>                
        <Table className={styles.Table}   highlightOnHover={true}>           
              {
               vNewsletter !== undefined && (                      
                  (<EmailHTMLTemplateTable   key={vKey}  Users={Users} Newsletter={vNewsletter}/>)        
                  )
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default EmailHTMLTemplate;


