
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  { listNewsletterGenerations }  from '../../../graphql/queries'
  import {
    updateNewsletter,
    deleteNewsletter,
    createNewsletterGeneration
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterImageURLTable.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import RhyteItTextField from './RhyteItTextField';  
  import RhyteItTextAreaFieldJSON from './RhyteItTextAreaFieldJSON';  
  
  
  
function NewsletterImageURLTable(
  props
) {       

  NewsletterImageURLTable.propTypes = {
    Newsletter: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vNewsletterActive, setvNewsletterActive] = useState(false)  
    let [vFieldNameSelected, setvFieldNameSelected] = useState()  
    let [vLabelSelected, setvLabelSelected] = useState()  
    let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 
    let [vNewsletterImageURLs, setvNewsletterImageURLs] = useState()
    let [vNewsletterGeneration, setvNewsletterGeneration] = useState()

    

    let vFieldButtons =  [
      {"FieldName" : "NewsletterBanner", "LabelName" : "Newsletter Banner", "FieldType" : "TextField"},
      {"FieldName" : "PodcastBanner", "LabelName" : "Podcast Banner", "FieldType" : "TextField"},
      {"FieldName" : "ContentSeperator", "LabelName" : "Content Seperator", "FieldType" : "TextField"}
    ]
        
      useEffect(() => {

        console.log("NewsletterImageURLTable.useEffect[].props.Newsletter:  ",props.Newsletter) 

      },[])  

      useEffect(() => {
      //console.log("NewsletterImageURLTable.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      


      
      useEffect(() => {

 
         if (vNewsletterImageURLs !== undefined) {
          console.log("NewsletterImageURLTable.useEffect[vNewsletterImageURLs].vNewsletterImageURLs: " ,vNewsletterImageURLs);
         }
 
       }, [vNewsletterImageURLs]);   

       useEffect(() => {

 
        if (vNewsletter !== undefined) {

         let  vImageURLs = {
          }
         
          if ("ImageURLs" in vNewsletter) {

            const vImageURLsJSON = JSON.parse(vNewsletter["ImageURLs"]);  
            
            if (vImageURLsJSON !== null) {     

                if ("NewsletterBanner" in vImageURLsJSON) {
                  vImageURLs["NewsletterBanner"] = vImageURLsJSON["NewsletterBanner"]
                } else {
                  vImageURLs["NewsletterBanner"] = ""
                } 

                if ("PodcastBanner" in vImageURLsJSON) {
                  vImageURLs["PodcastBanner"] = vImageURLsJSON["PodcastBanner"]
                } else {
                  vImageURLs["PodcastBanner"] = ""
                } 
       
                if ("ContentSeperator" in vImageURLsJSON) {
                  vImageURLs["ContentSeperator"] = vImageURLsJSON["ContentSeperator"]
                } else {
                  vImageURLs["ContentSeperator"] = ""
                } 


              } 

          } else {

            vImageURLs["NewsletterBanner"] = ""
            vImageURLs["PodcastBanner"] = ""
            vImageURLs["ContentSeperator"] = ""

          }

          setvNewsletterImageURLs(vImageURLs)

        }



      }, [vNewsletter]);   

      useEffect(() => {
        //console.log("NewsletterImageURLTable.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        setvFieldNameSelected(pExpandRow["FieldName"])
        setvLabelSelected(pExpandRow["LabelName"])
        setvFieldTypeSelected(pExpandRow["FieldType"])


          } catch (err) { 

          ErrorMessageStr = "NewsletterImageURLTable.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     

      return (
        <TableBody>         
          <TableRow  className={styles.TableRowActive}  key={vNewsletter.CreationDateTime}>     
          <TableCell className={styles.TableCellLeftAlign} colspan="6"> 
          <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="wrap"
               gap="1rem"
               className={styles.FlexRow}>   
          {
            vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (                      
              (<Button   key={vFieldButton.FieldName}  
                          className={styles.ButtonSection}
                          onClick={(e) => ExpandRow(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
              ))
          }            
                          
          </Flex>                     
                </TableCell>                  
              </TableRow>                           
                

          {
            vFieldTypeSelected === "TextField" &&  (                     
              <RhyteItTextField  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterImageURLs={vNewsletterImageURLs}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterImageURLs[vFieldNameSelected]}/>
            )          
          }
          {
            vFieldTypeSelected === "TextAreaField" &&  (                     
              <RhyteItTextAreaFieldJSON  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterImageURLs={vNewsletterImageURLs}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterImageURLs[vFieldNameSelected]}/>
            )          
          }
              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterImageURLTable;

