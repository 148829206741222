
import React, { useState, useEffect, useContext} from 'react'
//import './SignUp.css'; 
import {
    Authenticator,
    View,
    Image,
    ThemeProvider,
    useTheme,
    useAuthenticator,
    Flex,
    Text
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import styles from './AuthenticatorRhyteIt.module.css';  
import {useNavigate } from 'react-router-dom';
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import {createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";

const AuthenticatorRhyteIt = (props) => {
    AuthenticatorRhyteIt.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };
    
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [vUserEmail, setvUserEmail] = useState(props.UserName);
    const { tokens } = useTheme();
    const client = generateClient();
    var vFirstTouch = true   

    let navigate = useNavigate(); 

    const {Users, setUsers} = useContext(UserContext);    
    const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);   


    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  


      useEffect(() => {


          (async function() {
            try {  

                    getData()


                } catch (err) {
                  RhyteItLogging("NA", "AuthenticatorRhyteIt", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   


      },[])    

      
      useEffect(() => {
             
       // //console.log("AuthenticatorRhyteIt.useEffect[CognitoGroup].CognitoGroup:  ",CognitoGroup);
        (async function() {
          try {  
            if (CognitoGroup !== undefined) {
              GoToMenuSignedIn()
            }
           
              } catch (err) {                  
                RhyteItLogging(Users, "AuthenticatorRhyteIt", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            }
          }
        )();   
  
         },[CognitoGroup])        
          
          
      const components = {

          Header() {
            const { tokens } = useTheme();
        
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Image
                  alt="RhyteIt logo"
                  src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg"
                  className={styles.RhyteItlogo}      

                />
              </View>
            );
          }
      }

      const theme = {
          name: 'Auth Example Theme',
          tokens: {
            colors: {
              background: {
                primary: {
                  value: "transparent",
                },
                secondary: {
                  value: "white",
                },
                tertiary: {
                  value: "white",
                },
                quaternary: {
                  value: "white",
                },
              },
              brand: {
                primary: {
                  value: "yellow",
                },
                secondary: {
                  value: "white",
                },
              },
              border: {
                primary: {
                  value: "transparent",
                },
                secondary: {
                  value: "white",
                },
              },
              font: {
                primary: {
                  value: "white",
                },
                secondary: {
                  value: "white",
                },
                tertiary: {
                  value: "white",
                },
                interactive: {
                  value: "#00CCCC",
                }
              }
            },             
            text: {
              color: {
                primary: {
                  value: "white",
                },
                secondary: {
                  value: "white",
                },
              }
            },

          }
      };  
      
      async function GetCognitoGroup() {
        try {

              const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
              //console.log("AuthenticatorRhyteIt.GetCognitoGroup.idToken:  ",idToken);
              //console.log("AuthenticatorRhyteIt.GetCognitoGroup.accessToken:  ",accessToken);
              

              setUsers(idToken.payload.email)
              setCognitoGroup(accessToken["payload"]["cognito:groups"])


            } catch (err) { 
              //console.error('AuthenticatorRhyteIt.GetCognitoGroup.Error', err)
              RhyteItLogging(Users, "AuthenticatorRhyteIt", "GetCognitoGroup",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }                      


      }


      async function GoToMenuSignedIn() {
            try {
              let vCognitoGroup = 0
              //console.log("AuthenticatorRhyteIt.GoToMenuSignedIn.authStatus: " ,authStatus);
           
                if (CognitoGroup !== undefined) {

                  vCognitoGroup = Object.values(CognitoGroup).filter(cg => {
                    return cg ===   "rhyteitAdmins";
                  }).length; 
                  
                }
      
                
                if (authStatus === "authenticated" && vCognitoGroup > 0) {

                    navigate('/MainMenuV2', 
                    {
                      state: {
                        UserId:Users,                                
                        UserName:Users,                                
                        CognitoGroup:CognitoGroup,
                        authStatus: authStatus
                      }
                    }
                  );  

                
                  } 
                else if (authStatus === "authenticated" && vCognitoGroup === 0) {    
                  navigate('/MainMenuCustomer', 
                  {
                    state: {
                      UserId:Users,                                
                      UserName:Users,                                
                      CognitoGroup:CognitoGroup,
                      authStatus: authStatus
                    }
                  }
                  );                    
                }


              } catch (err) { 
                console.error('AuthenticatorRhyteIt.GoToMenuSignedIn.Error', err)
                RhyteItLogging(Users, "AuthenticatorRhyteIt", "GoToMenuSignedIn",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            }        
      }      
          
  const getData = async () => {

                                  

    if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
           vFirstTouch = false;    
  
        let DynamoUUID =  await uuidv4()
  
        let AudienceGeoItem = {
          id: DynamoUUID,
          UserId: "NA",
          Page: "AuthenticatorRhyteIt",
          IPv4: "000.00.00.000",
          city: "NA",
          country_code:"NA",
          country_name: "NA",
          latitude: "00.000",
          longitude: "00.000",
          postal: "NA",
          state: "NA",
          CreationName:  "AuthenticatorRhyteIt",
          UpdateName: "AuthenticatorRhyteIt"
        }                      
        
        try{
  
          let createAudienceGeoInfoResponse = await client.graphql({                    
            query: createAudienceGeoInfo,
            variables:{
                input: AudienceGeoItem
              }
        });         
            
  
        } catch (err)  {
          RhyteItLogging("NA", "News", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
       }
  
    }
  
  }      

    return (        
                <m.div
                  key="AuthenticatorRhyteIt"
                  initial={{x: "100%"}}
                  animate={{x: "0%"}}
                  transition={{duration:0.75, ease: "easeOut"}}
                  exit={{ opacity: 1 }}
                  className={styles.AuthenticatorRhyteIt}
                >
                <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0f0f14"  GradientStopColor2="#1f1f29" GradientStopColor3="#000000" GradientStopColor4="#21212c"/> 
                <div className={styles.AuthenticatorContainer}>     
                    <ThemeProvider theme={theme}>
                        <Authenticator hideSignUp="true" 
                          components={components} 
                            >
                            {({ signOut, user }) => (
                                <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="nowrap"
                                className={styles.Loginarea}
                              >            
                                  <Text  className={styles.Text} >Login Successful for: {vUserEmail}</Text>
                                  <button  className={styles.metallicbutton} onClick={(e) => GetCognitoGroup()}>Enter</button>
                                  <Text  className={styles.Text}>{ErrorMessage}</Text>
                                  <Text  className={styles.Text}>{authStatus}</Text>
                                </Flex>
                            )}  
                        </Authenticator>
                    </ThemeProvider>
        
        
                </div>   
            </m.div>
                
    );
}


export default AuthenticatorRhyteIt;    