import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
 import styles from './HamburgerMenu.module.css';  
import {
  Flex,
  Text,
  useAuthenticator,
Image
  } from '@aws-amplify/ui-react';
  import axios from 'axios';
  import {uuidv4} from "../../BusinessLogic/UtilitiesManagement"; 
  
import  {createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import { RhyteItLogging} from '../../BusinessLogic/UserManagement';

  function HamburgerMenu({
    NavBarMenuClicked,
    onClick
  }) {

  HamburgerMenu.propTypes = {
      NavBarMenuClicked: PropTypes.bool,
      onClick: PropTypes.func
    };

    var vFirstTouch = true
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);    
    const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);  
  
useEffect(() => {



     // console.log("HamburgerMenu.useEffect[].NavBarMenuClicked: " ,NavBarMenuClicked);

      (async function() {
        try {  

            getData()
            
        } catch (err) {
          console.error('LandingPageNavBar.useEffect[].ERROR: ', err, err.stack); 
      }
    }
  )();   
          
},[])   

useEffect(() => {


   },[CognitoGroup])             
    

const getData = async () => {


 
  if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
      //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
         vFirstTouch = false;    


   //   const res = await axios.get('https://geolocation-db.com/json/')

      let DynamoUUID =  await uuidv4()

   

      let AudienceGeoItem = {
        id: DynamoUUID,
        UserId: "NA",
        Page: "HamburgerMenu",
        IPv4: "000.00.00.000",
        city: "NA",
        country_code: "NA",
        country_name: "NA",
        latitude: "00.000",
        longitude:  "00.000",
        postal: "NA",
        state: "NA",
        CreationName:  "HamburgerMenu",
        UpdateName: "HamburgerMenu"
      }                      

      //console.log("LandingPageNavBar.getData.AudienceGeoItem: " ,AudienceGeoItem)

      try{

        let createAudienceGeoInfoResponse = await client.graphql({                    
          query: createAudienceGeoInfo,
          variables:{
              input: AudienceGeoItem
            }
      });         
          

      } catch (err)  {
        RhyteItLogging("NA", "HamburgerMenu", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
     }

 
  }

}




  return (
                      <Flex
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="stretch"
                              alignContent="flex-start"
                              wrap="nowrap"
                              className={styles.MenuItems}
                              onClick={onClick}
                            >        
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineBlue.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />         
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineOrange.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />            
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLinePurple.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />                                  
                      </Flex> 
  )
}

export default HamburgerMenu;