
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  { listNewsletterGenerations }  from '../../../graphql/queries'
  import {
    updateNewsletter,
    deleteNewsletter,
    createNewsletterGeneration
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterGenerationTable.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import RhyteItTextField from './RhyteItTextField';  
  import RhyteItTextAreaFieldJSON from './RhyteItTextAreaFieldJSON';  
  
  
  
function NewsletterGenerationTable(
  props
) {       

  NewsletterGenerationTable.propTypes = {
    Newsletter: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vNewsletterActive, setvNewsletterActive] = useState(false)  
    let [vFieldNameSelected, setvFieldNameSelected] = useState()  
    let [vLabelSelected, setvLabelSelected] = useState()  
    let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 
    let [vNewsletterGeneration, setvNewsletterGeneration] = useState()

    
 

    let vFieldButtons =  [
      {"FieldName" : "HtmlHeadSection", "LabelName" : "Html Head Section", "FieldType" : "TextAreaField"},
      {"FieldName" : "HtmlStyleSection", "LabelName" : "Html Style Section", "FieldType" : "TextAreaField"},
      {"FieldName" : "HtmlHeaderSection", "LabelName" : "Html Header Section", "FieldType" : "TextAreaField"},
      {"FieldName" : "HtmlAuthorInfoSection", "LabelName" : "Html Author Info Section", "FieldType" : "TextAreaField"},
      {"FieldName" : "HtmlFooterSection", "LabelName" : "Html Footer Section", "FieldType" : "TextAreaField"},
      {"FieldName" : "HtmlGooglePublishingSection", "LabelName" : "Html Google Publishing Section", "FieldType" : "TextAreaField"},
    ]
        
      useEffect(() => {

        //console.log("NewsletterGenerationTable.useEffect[].props.Newsletter:  ",props.Newsletter) 

      },[])  

      useEffect(() => {
      //console.log("NewsletterGenerationTable.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("NewsletterGenerationTable.useEffect[vNewsletter].vNewsletter: " ,vNewsletter);

        if (vNewsletter !== undefined) {
          GetNewsletterGeneration()
        }

      }, [vNewsletter]);   
      
      useEffect(() => {

 
         if (vNewsletterGeneration !== undefined) {
          //console.log("NewsletterGenerationTable.useEffect[vNewsletterGeneration].vNewsletterGeneration: " ,vNewsletterGeneration);
         }
 
       }, [vNewsletterGeneration]);   

      

      useEffect(() => {
        //console.log("NewsletterGenerationTable.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("NewsletterGenerationTable.ExpandRow.FieldName: " ,pExpandRow["FieldName"]);
        //console.log("NewsletterGenerationTable.ExpandRow.LabelName: " ,pExpandRow["LabelName"]);
        //console.log("NewsletterGenerationTable.ExpandRow.FieldType: " ,pExpandRow["FieldType"]);
        //console.log("NewsletterGenerationTable.ExpandRow.vNewsletter);: " ,vNewsletter);
        setvFieldNameSelected(pExpandRow["FieldName"])
        setvLabelSelected(pExpandRow["LabelName"])
        setvFieldTypeSelected(pExpandRow["FieldType"])


          } catch (err) { 

          ErrorMessageStr = "NewsletterGenerationTable.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     
     
      async function GetNewsletterGeneration() {
        try {
          setErrorMessage("");
          
          //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
          let variables;
          if (Users !== "edwardhawke@rhyteit.com") {
            variables = {
              filter: {
                Newsletter: {
                  eq: vNewsletter["Newsletter"],
                },
                AccountGroup: {
                  eq: vNewsletter["AccountGroup"],
                },
              },
            };
          } else {
            variables = {};
          }
    
          const listNewsletterGenerationsData2 = await client.graphql({
            query: listNewsletterGenerations,
            variables: variables,
          });
    
          //console.log("NewsletterGenerationTable.GetNewsletterGeneration.listNewsletterGenerations.data.listNewsletterGenerations.items.length : ", listNewsletterGenerationsData2.data.listNewsletterGenerations.items.length );
    
          if (listNewsletterGenerationsData2.data.listNewsletterGenerations.items.length > 0) {
            const NewsletterGeneration1 =
              listNewsletterGenerationsData2.data.listNewsletterGenerations.items[0];
              setvNewsletterGeneration(NewsletterGeneration1)
          } else {
            CreateNewsletterGeneration()
          }
    
    
    
        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterGenerationTable",
            "GetNewsletterGeneration",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }

      async function CreateNewsletterGeneration() {
        try {
          //console.log("NewsletterManagerTable.CreateNewletter.vNewNewsletter:  ",vNewNewsletter);
    
          var vUpdateDateTimeNew = new Date().toISOString();
    
          let NewsletterGenerationItem = {
            id: vNewsletter["Newsletter"].replace(/\s+/g, '-') + vNewsletter["AccountGroup"].replace(/\s+/g, '-') ,
            Newsletter: vNewsletter["Newsletter"],
            AccountGroup: vNewsletter["AccountGroup"],
            CampaignName: "",
            OpenAIBlogPrompt:  "",
            OpenAIBlogTrendPrompt:  "",
            OpenAINewsPrompt:  "",
            OpenAINewsTrendPrompt:  "",
            OpenAIHTMLPrompt:  "",
            OpenAIIntroductionPrompt:  "",
            OpenAIPodcastPrompt:  "",
            OpenAIPrompts:  null,
            HtmlHeadSection:  "",
            HtmlStyleSection:  "",
            HtmlHeaderSection:  "",
            HtmlAuthorInfoSection:  "",
            HtmlFooterSection:  "",
            HtmlGooglePublishingSection:  "",
            NewsletterGenerationStatus:  "new",
            CreationName: Users,
            CreationDateTime: vUpdateDateTimeNew,
            UpdateName: Users,
            UpdateDateTime: vUpdateDateTimeNew,
          };
    
          //console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",CampaignItem);
    
          const createNewsletterGenerationResponse = await client.graphql({
            query: createNewsletterGeneration,
            variables: {
              input: NewsletterGenerationItem,
            },
          });
          
          
          //console.log("NewsletterManagerTable.CreateNewsletterGeneration.createNewsletterGenerationResponse:  ",createNewsletterGenerationResponse);
    

          setvNewsletterGeneration(NewsletterGenerationItem);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterManager",
            "CreateNewletter",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }
    

      return (
        <TableBody>         
          <TableRow  className={styles.TableRowActive}  key={vNewsletter.CreationDateTime}>     
          <TableCell className={styles.TableCellLeftAlign} colspan="6"> 
          <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="wrap"
               gap="1rem"
               className={styles.FlexRow}>   
          {
            vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (                      
              (<Button   key={vFieldButton.FieldName}  
                          className={styles.ButtonSection}
                          onClick={(e) => ExpandRow(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
              ))
          }            
                          
          </Flex>                     
                </TableCell>                  
              </TableRow>                           
                

          {
            vFieldTypeSelected === "TextField" &&  (                     
              <RhyteItTextField  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterGeneration={vNewsletterGeneration}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterGeneration[vFieldNameSelected]}/>
            )          
          }
          {
            vFieldTypeSelected === "TextAreaField" &&  (                     
              <RhyteItTextAreaFieldJSON  key={vFieldNameSelected}  Users={Users} Newsletter={vNewsletter} NewsletterGeneration={vNewsletterGeneration}  RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vNewsletterGeneration[vFieldNameSelected]}/>
            )          
          }
              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterGenerationTable;

