
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Image,
  Button,
  TextField,
  Flex
  } from '@aws-amplify/ui-react'; 
import NewsletterSubscriberRow from './NewsletterSubscriberRow';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './NewsletterSubscriberTable.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listNewsletterSubscribers,
  listSMMADAccounts,
  listSMMADAccountGroups,
} from "../../../graphql/queries";
import {
  createNewsletter,
  createNewsletterSubscribers
} from "../../../graphql/mutations";


const NewsletterSubscriberTable = (props) => {       

  NewsletterSubscriberTable.propTypes = {
      Newsletter: PropTypes.array,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)   
    const [Users, setUsers] = useState(props.Users)   
    const [vSubscribers, setvSubscribers] = useState()    
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [vSubscribersRefresh, setvSubscribersRefresh] = useState()  
    let [vNewSubscriber, setvNewSubscriber] = useState();
    const [vAddSubscribersVisible, setvAddSubscribersVisible] = useState(false);
    const [updatedKey, setupdatedKey] = useState();

    useEffect(() => {

    },[])  

    useEffect(() => {
      //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
   
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {

     //console.log("NewsletterSubscriberTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)
      //console.log("NewsletterSubscriberTable.useEffect[].vSubscribers ",vSubscribers) 
      //console.log("vNewsletterSubscriberTable.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vNewsletterSubscriberTable.useEffect[].Time:  ", NewsletterSubscriberRow.PostDateTime.substr(NewsletterSubscriberRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vNewsletterSubscriberTable.useEffect[].indexOf:  ", NewsletterSubscriberRow.PostDateTime.indexOf(":00"))
      if (vSubscribers !== undefined) {
        if (vSubscribers.length > 0) {
          setShowTable(true)        
          setvUpdateKey(Date.now())
          setvSubscribersRefresh(Date.now)
          }
      }

    },[vSubscribers])  

    useEffect(() => {
      console.log("NewsletterSubscriberTable.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)
  
      if (vNewsletter !== undefined) {
        GetNewsletterSubscriberTable()
      }
    }, [vNewsletter]);
  
    useEffect(() => {
      //console.log("NewsletterSubscriberTable.useEffect[vHostEmail].vHostEmail:  ",vHostEmail)

      if (vSubscribers !== undefined) {

        setvUpdateKey(Date.now())

       }
 
     },[vSubscribers])     


     async function GetNewsletterSubscriberTable() {
      try {
        setErrorMessage("");
        
        console.log("NewsletterSubscriberTable.GetNewsletterSubscriberTable.Users: ", Users);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterSubscribersData2 = await client.graphql({
          query: listNewsletterSubscribers,
          variables: variables,
        });
  
        console.log("NewsletterSubscriberTable.GetNewsletterSubscriberTable.listNewsletterSubscribers.data.listNewsletterSubscribers.items.length : ", listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length );
  
        if (listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length > 0) {
          const NewsletterSubscriberTable1 =
            listNewsletterSubscribersData2.data.listNewsletterSubscribers.items;
            let vSortedSubscribers = SortSubscribersByName(NewsletterSubscriberTable1)
            setvSubscribers(vSortedSubscribers)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "GetNewsletterSubscriberTable",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    function SortSubscribersByName(pSubscriberEmail) {
      pSubscriberEmail.sort(GFG_sortFunction);
      return pSubscriberEmail;
      //    //console.log(JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let SubscriberEmailA = a.SubscriberEmail;
      let SubscriberEmailB = b.SubscriberEmail;
      return SubscriberEmailA > SubscriberEmailB ? 1 : -1;
    }
  
    function NewNewsletter_handleChange(event, data) {
      try {
        setvNewSubscriber(event.target.value);
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "NewNewsletter_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    function convertToISO(datetimeStr) {
      // Create a new Date object from the provided string
      let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
      
      // Convert it to ISO string
      return date.toISOString();
    }  

  
    async function CreateSubscriber() {
      try {
        console.log("NewsletterSubscriberTable.CreateSubscriber.vNewSubscriber:  ",vNewSubscriber);
        let datetimeStr = "1999-01-01 01:00:00";
        let isoDate = convertToISO(datetimeStr);
        var vUpdateDateTimeNew = new Date().toISOString();
        const vSubscriptionPreferencesJSONString = JSON.stringify({"frequency": "weekly", "content": "news"});   
  
        let SubscriberItem = {
          id: vNewSubscriber,
          Newsletter: vNewsletter["Newsletter"],
          AccountGroup: vNewsletter["AccountGroup"],
          SubscriberEmail: vNewSubscriber,
          SubscriptionStatus: "subscribed",
          SubscribedDate: vUpdateDateTimeNew,
          SubscriberSegment: "",
          LastEmailSentDate: isoDate,
          LastEmailOpenedDate: isoDate,
          SendCount: 0,
          OpenCount:  0,
          ClickCount: 0,
          SubscriptionSource: "Manual",
          Geolocation: "Unknown",
          SubscriptionPreferences: vSubscriptionPreferencesJSONString,
          Active: true,
          CreationName: Users,
          CreationDateTime: vUpdateDateTimeNew,
          UpdateName: Users,
          UpdateDateTime: vUpdateDateTimeNew,
        };
  
    
  
        //console.log("NewsletterSubscriberTable.SaveRow.CampaignItem:  ",CampaignItem);
  
        const createNewsletterResponse = await client.graphql({
          query: createNewsletterSubscribers,
          variables: {
            input: SubscriberItem,
          },
        });
        setvAddSubscribersVisible(false);
        setvNewSubscriber("");
        GetNewsletterSubscriberTable();
      } catch (err) {
        console.error(err); 

        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "CreateSubscriber",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    async function ViewConfig(event) {
      try {
        //console.log("NewsletterSubscriberTable.ViewConfig.event: ", event);
        //console.log("NewsletterSubscriberTable.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
        setvAddSubscribersVisible(!vAddSubscribersVisible)
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "ViewConfig",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

        function Field_handleChange(event, data, pFieldName) {
          try {
    
    
            let vTmp = event.target.value
    
            setvNewSubscriber(vTmp)
    
          } catch (err) {
            RhyteItLogging(
              Users,
              "NewsletterSubscriberTable",
              "Field_handleChange",
              err.toString(),
              '{"ERROR": "' + err.toString() + '"}',
              0
            );
          }
        }            
        


  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vNewsletter !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
            

        <motion.div 
              className = {styles.NewsletterTitleDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterTitle}>
              {vNewsletter["Newsletter"]}
            </motion.h1>   
        </motion.div>             
        <motion.div 
              className = {styles.NewsletterDescriptionDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterDescription}>
              {vNewsletter["Description"]}
            </motion.h1>   
        </motion.div>  
        <motion.div 
              className = {styles.SubscribersDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.Subscribers}>
              Newsletter Subscribers
            </motion.h1> 
            <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>                   
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => ViewConfig(e)}
              >
                Add Subscriber
            </Button>
            {
              vAddSubscribersVisible === true && ( 
            <TextField
                className={styles.TextField}
                name={vNewSubscriber}
                placeholder={vNewSubscriber}
                defaultValue={vNewSubscriber}
                onChange={(e) => Field_handleChange(e, vNewSubscriber, "vNewSubscriber")}
              />      
              )}     
              {
                vAddSubscribersVisible === true && (            
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => CreateSubscriber()}
              >
                  Save Subscriber
              </Button>     
              )} 
            </Flex>                     
        </motion.div>           
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Expand</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Email</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Subscribed Date</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Last Email Sent</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Last Email Opened</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Send #</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Open #</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Click #</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Update</TableCell>
            </TableRow>                                      
          </TableHead>       
          
              {
                vSubscribers !== undefined && vNewsletter !== undefined && vSubscribers.map((Subscriber) => (                      
                  (<NewsletterSubscriberRow   key={Subscriber.id}  Users={Users} Newsletter={vNewsletter} Subscriber={Subscriber}/>)        
                  ))
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default NewsletterSubscriberTable;


