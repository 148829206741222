import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { generateClient } from 'aws-amplify/api';
import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";

import {motion as m} from "framer-motion";

import MenuBackground from '../Menu/MenuBackground';
import OFProfileCard from "./OFProfileCard";

import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { SelectedProfileContext} from '../../BusinessLogic/Hooks/ACCProfileContext';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import {createAudienceGeoInfo}  from '../../graphql/mutations'

import {
  Button ,
  SliderField,
  TextField,
  Text,
  Flex,  
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Divider
} from '@aws-amplify/ui-react';
import styles from './AdultContentCreatorSearch.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const AdultContentCreatorSearch = (props) => {       
  
  AdultContentCreatorSearch.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [OnlyFansProspectingProfiles, setOnlyFansProspectingProfiles] = useState()   
        const [OnlyFansProspectingProfilesData, setOnlyFansProspectingProfilesData] = useState([])              
              
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [sliderValue, setsliderValue] = React.useState(0);  
        let debugConsoleOutPut = true

        const [SearchTerm1, setSearchTerm1] = useState('');
        const [ProfilesReturned, setProfilesReturned] = useState(0);
        
        
        const [vClickedImage, setvClickedImage] = useState("lilianaheartsss")  

        
        const [vFindButtonClicked, setvFindButtonClicked] = useState(false);
        const [vPercentileButtonClicked, setvPercentileButtonClicked] = useState(false);
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext); 
        var vFirstTouch = true   

        useEffect(() => {

          (async function() {
            try {  

                    getData()
                    GetOnlyFansProspectingProfilesFromS3_100()


                } catch (err) {
                  RhyteItLogging(Users, "AdultContentCreatorSearch", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {

          (async function() {
            try {  

                  //console.log("AdultContentCreatorSearch.useEffect[vFindButtonClicked].vFindButtonClicked: ",vFindButtonClicked);
                    
                  if (vFindButtonClicked === true) {
                    GetOFBySearchField()
                  }


                } catch (err) {
                  RhyteItLogging(Users, "AdultContentCreatorSearch", "useEffect[vFindButtonClicked]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
      

        },[vFindButtonClicked]) 

        useEffect(() => {

          (async function() {
            try {  
              //console.log("AdultContentCreatorSearch.useEffect[vPercentileButtonClicked].vPercentileButtonClicked: ",vPercentileButtonClicked);
                    
              if (vPercentileButtonClicked === true) {
                GetOFByEngagementScorePercentile()
              }

                } catch (err) {
                  RhyteItLogging(Users, "AdultContentCreatorSearch", "useEffect[vPercentileButtonClicked]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
      

        },[vPercentileButtonClicked])         
         

        useEffect(() => {

          //console.log("AdultContentCreatorSearch.useEffect[vClickedImage].vClickedImage: ", vClickedImage)

        },[vClickedImage])  

        const getData = async () => {
 
          if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
                 vFirstTouch = false;    
        
              let DynamoUUID =  await uuidv4()
        
              let AudienceGeoItem = {
                id: DynamoUUID,
                UserId: "NA",
                Page: "AdultContentCreatorSearch",
                IPv4: "000.00.00.000",
                city: "NA",
                country_code:"NA",
                country_name: "NA",
                latitude: "00.000",
                longitude: "00.000",
                postal: "NA",
                state: "NA",
                CreationName:  "AdultContentCreatorSearch",
                UpdateName: "AdultContentCreatorSearch"
              }                      
              
              try{
        
                let createAudienceGeoInfoResponse = await client.graphql({                    
                  query: createAudienceGeoInfo,
                  variables:{
                      input: AudienceGeoItem
                    }
              });         
                  
        
              } catch (err)  {
                RhyteItLogging("NA", "AdultContentCreatorSearch", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
             }
        
          }
        
        }

        async function GetOnlyFansProspectingProfilesFromS3() {
          try {

                    if (debugConsoleOutPut === true) {
                        //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                      //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users[0].UserId: ",Users[0].UserId)
                    }

                    let vOFJSON = ""

                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/RhyteItEngagementScore.json`

                    const fetchResponse = await fetch(url);
                    let OnlyFansProspectingProfilesData1 = await fetchResponse.json();
                    //console.log("GetOnlyFansProspectingProfilesFromS3.OnlyFansProspectingProfilesData.length: ",OnlyFansProspectingProfilesData1.length);

                    setOnlyFansProspectingProfilesData(OnlyFansProspectingProfilesData1)                    

                    let vProfileTotal = OnlyFansProspectingProfilesData1.length
                    setProfilesReturned(vProfileTotal.toLocaleString())               
      

  
          } catch (err) { 
            RhyteItLogging(Users, "AdultContentCreatorSearch", "GetOnlyFansProspectingProfilesFromS3",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }  
        
        async function GetOnlyFansProspectingProfilesFromS3_100() {
          try {

                    if (debugConsoleOutPut === true) {
                        //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                      //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users[0].UserId: ",Users[0].UserId)
                    }

                    let vOFJSON = ""

                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/RhyteItEngagementScore100.json`

                    const fetchResponse = await fetch(url);
                    let OnlyFansProspectingProfilesData1 = await fetchResponse.json();
                    //console.log("GetOnlyFansProspectingProfilesFromS3.OnlyFansProspectingProfilesData.length: ",OnlyFansProspectingProfilesData1.length);

                    setOnlyFansProspectingProfilesData(OnlyFansProspectingProfilesData1)                    

                    let vProfileTotal = OnlyFansProspectingProfilesData1.length
                    setProfilesReturned(vProfileTotal.toLocaleString())
                    //console.log("OnlyFansProspectingProfilesData1: ",OnlyFansProspectingProfilesData1[1]);

                    let vProfileList = []

                    let vBeginIndex = Math.trunc(vProfileTotal*0)
                    let vEndIndex = Math.trunc(vProfileTotal*.01)
                    let vIndex = vBeginIndex

                      while (vBeginIndex <= vIndex && vIndex < vEndIndex) {
                        
                        vProfileList.push(OnlyFansProspectingProfilesData1[vIndex])                        

                        vIndex += 1

                      }


                      let vOFSubset = []
                      let vIter = 0
                      const OFSubset = OnlyFansProspectingProfilesData1.forEach(OFProfile => { 
                        
                         // console.log("TreeGrowth.GetOnlyFansProspectingProfilesFromS3.OFProfile.Twitter: ",OFProfile.Twitter);
                          if (vIter < 100 && OFProfile.OnlyFansProfilePictureURL.indexOf("Tag") === -1 && OFProfile.OnlyFansProfilePictureURL.length > 0 && OFProfile.Twitter.length > 0) {
                              vOFSubset.push(OFProfile) 
                              vIter += 1
                            } 
                          }
                      );
                                          

                    //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfilesFromS3_100.OnlyFansProspectingProfilesData1: ",OnlyFansProspectingProfilesData1.length);

                    setOnlyFansProspectingProfiles(vOFSubset)
                    
                    GetOnlyFansProspectingProfilesFromS3() 

                     //let PopulateOnlyFansNamesListResponse = await PopulateOnlyFansNamesList(OnlyFansProspectingProfilesData1)                      
      

  
          } catch (err) { 
                          RhyteItLogging(Users, "AdultContentCreatorSearch", "GetOnlyFansProspectingProfiles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }         

        async function GetOFByEngagementScorePercentile() {
          try {

            

                    setErrorMessage("")
                    setOnlyFansProspectingProfiles([])    
                    if (debugConsoleOutPut === true) {
                        //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                      //console.log("AdultContentCreatorSearch.GetOnlyFansProspectingProfiles.Users[0].UserId: ",Users[0].UserId)
                    }


                    let vProfileTotal = OnlyFansProspectingProfilesData.length
                    //console.log("OnlyFansProspectingProfilesData: ",OnlyFansProspectingProfilesData[1320]);
                    //console.log("GetOFByEngagementScorePercentile.OnlyFansProspectingProfilesData.length: ",OnlyFansProspectingProfilesData.length);

                    let vProfileList = []

                    let vBeginIndex = Math.trunc(vProfileTotal*(sliderValue*0.01))
                    let vEndIndex = Math.trunc(vProfileTotal*((sliderValue+1)*0.01))
                    let vIndex = vBeginIndex

                    //console.log("GetOFByEngagementScorePercentile.vProfileTotal: " , vProfileTotal, " - .vBeginIndex: " , vBeginIndex, " - vEndIndex: " , vEndIndex);

                      while (vBeginIndex <= vIndex && vIndex < vEndIndex) {
                        
                        vProfileList.push(OnlyFansProspectingProfilesData[vIndex])                        

                        vIndex += 1

                      }


                    //console.log("vProfileList: ",vProfileList);
                    //console.log("GetOFByEngagementScorePercentile.vProfileList.length: ",vProfileList.length);
                    setProfilesReturned(vProfileList.length.toLocaleString())
                    //const OnlyFansProspectingProfiles = vProfileList

                    setOnlyFansProspectingProfiles(vProfileList)
                    
                    setvPercentileButtonClicked(false)

                    return OnlyFansProspectingProfiles
  
          } catch (err) { 
            RhyteItLogging(Users, "AdultContentCreatorSearch", "GetOFByEngagementScorePercentile",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }          

        async function GetOFBySearchField() {
          try {

                  if (debugConsoleOutPut === true) {
                    //console.log("AdultContentCreatorSearch.GetOFBySearchField.vFindButtonClicked: ", vFindButtonClicked)
                    //console.log("AdultContentCreatorSearch.GetOFBySearchField.OnlyFansProspectingProfilesData.length: ", OnlyFansProspectingProfilesData.length)
                    //console.log("AdultContentCreatorSearch.GetOFBySearchField.SearchTerm1: ", SearchTerm1)
                }
  
                    setErrorMessage("")
                    setOnlyFansProspectingProfiles([])
                    setsliderValue(0)
         

                    let vProfileList = []
                    //console.log("GetOFBySearchField.SearchTerm1: " , SearchTerm1);

                    //let OFItem = OnlyFansProspectingProfilesData.find( record => record.id === SearchTerm1 );
                    let OFItem = OnlyFansProspectingProfilesData.filter((ele,index) => ele.id=== SearchTerm1 );

                    if(OFItem.length > 0) {
                        
                      vProfileList.push(OFItem[0])   
                      setProfilesReturned(vProfileList.length)
                      setOnlyFansProspectingProfiles(vProfileList) 
                    }
                    else {
                      setSearchTerm1(SearchTerm1 + " not found")
                    } 

                    setvFindButtonClicked(false)
  
          } catch (err) { 
            RhyteItLogging(Users, "AdultContentCreatorSearch", "GetOFBySearchField",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }        

        function FindField_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
                //console.log("AdultContentCreatorSearch.FindField_handleChange.event.target.value: ",event.target.value)
              }
    
              setSearchTerm1(event.target.value)
    
            } catch (err) { 
              RhyteItLogging(Users, "AdultContentCreatorSearch", "FindField_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }   
         
        function FindButtonClicked(event) {
          try {           
            
            
            //console.log("AdultContentCreatorSearch.FindButtonClicked.OnlyFansProspectingProfiles.length: ",OnlyFansProspectingProfiles.length);
              
            if (OnlyFansProspectingProfiles.length < 110) {
              GetOnlyFansProspectingProfilesFromS3()     
            } 
    
            setvFindButtonClicked(true)

    
            } catch (err) { 
              RhyteItLogging(Users, "AdultContentCreatorSearch", "FindButtonClicked",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }  
    
        function PercentileButtonClicked(event) {
          try {           
            
            
            //console.log("AdultContentCreatorSearch.PercentileButtonClicked.OnlyFansProspectingProfiles.length: ",OnlyFansProspectingProfiles.length);
              
            if (OnlyFansProspectingProfiles.length < 110) {
              GetOnlyFansProspectingProfilesFromS3()     
            } 
    
            setvPercentileButtonClicked(true)

    
            } catch (err) { 
              RhyteItLogging(Users, "AdultContentCreatorSearch", "PercentileButtonClicked",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }  
            

    return ( 
    <m.div
      key="Search"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
  
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#484890"   GradientStopColor4="#8880"/>                        
                    <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="5em"
                    className = {styles.GridContainer}>                                            
                    <HeaderMenu  className={styles.RhyteItHeaderMenu}  pageSource="ACCSearch" />
                    <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem">                          
                      <h1 className={styles.h1}>Adult Content Creator Engagement Rankings</h1>  
                      <div className={styles.headerline} />
                      <h2 className={styles.h2}>Find the top adult content creators based on content output and fan engagement</h2>  
                      <Table className={styles.Table}   highlightOnHover={true}>   
                        <TableBody>                          
                          <TableRow  className={styles.TableRow}>
                            <TableCell as="td" className={styles.TableCell}>                              
                              <SliderField
                                className={styles.Slider}
                                isValueHidden={true}
                                value={sliderValue}                                  
                                onChange={setsliderValue}
                                max={100}
                              />             
                            </TableCell>
                            <TableCell as="td" className={styles.TableCell}>                         
                              <Button
                                    loadingText=""
                                    ariaLabel=""
                                    className={styles.metallicbutton}
                                    onClick={ (e) => PercentileButtonClicked(e)}
                                  >
                                  {sliderValue} percentile
                              </Button>   
                            </TableCell>
                          </TableRow>                          
                          <TableRow  className={styles.TableRow}>
                            <TableCell as="td" className={styles.TableCell}>       
                              <TextField label="Search" 
                                  labelHidden={true} 
                                  className={styles.TextField}
                                  defaultValue={SearchTerm1}
                                  value={SearchTerm1}
                                  placeholder='Ex: sweetesttay'
                                  onChange={ (e) => FindField_handleChange(e, SearchTerm1) }
                                  />        
                            </TableCell>
                            <TableCell as="td" className={styles.TableCell}>    
                              <Button
                                  loadingText=""
                                  ariaLabel=""
                                  className={styles.metallicbutton}
                                  onClick={ (e) => FindButtonClicked(e, SearchTerm1)}
                                >
                                Find
                                </Button>   
                            </TableCell>
                          </TableRow>  
                        </TableBody>                                    
                      </Table>                  
                      <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem"
                        className = {styles.ToolBar}>   
                        <Divider />  
                        <Text className = {styles.Text}> OF Profiles returned:  
                          </Text>  
                        <Text className = {styles.Text}>{ProfilesReturned} 
                          </Text>                             
                      </Flex>                            
                      <Divider />  
                      <SelectedProfileContext.Provider value={vClickedImage}>
                      <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                    >      
                      {    
                          vClickedImage && OnlyFansProspectingProfiles !== undefined && OnlyFansProspectingProfiles.map((vOnlyFansProfile) => (  
                            <OFProfileCard key={vOnlyFansProfile.id} 
                              OFProfile={vOnlyFansProfile}
                              ClickedImage={vClickedImage}  
                              onClick={() =>setvClickedImage(vOnlyFansProfile.id)} 
                              />      
                        ))
                      }     
                      </Flex>   
                      </SelectedProfileContext.Provider>                   
                    </Flex>                                                                                 
                  </Flex>    
            </ErrorBoundary>
          </div>
    </m.div>                      

    )

  }
 

export default AdultContentCreatorSearch;