import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./LandingPageV5.module.css";
import { SEO } from "../SEO/SEO";

import LogoName from "../HeaderMenu/LogoName";
import HamburgerMenu from "../HeaderMenu/HamburgerMenu";

import Villain from "./StoryBrandPages/Villain";
import Hero from "./StoryBrandPages/Hero";
import Solution from "./StoryBrandPages/Solution";
import OurStory from "./StoryBrandPages/OurStory";
import Problem from "./StoryBrandPages/Problem";
import Benefits from "./StoryBrandPages/Benefits";
import NewsLetter from "./StoryBrandPages/NewsLetter";
import LetsTalk from "./StoryBrandPages/LetsTalk";
import PodcastEpisodes from "../Podcast/PodcastEpisodes";


import Menu from "./Menus/Menu";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useSpring,
  AnimatePresence,
  useMotionValue,
  useMotionValueEvent,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import Footer from "../RhyteItComponents/Footer/Footer";
import FooterV2 from "../RhyteItComponents/Footer/FooterV2";
import { uuidv4 } from "../../BusinessLogic/UtilitiesManagement";
import { createAudienceGeoInfo } from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
} from "../../BusinessLogic/UserManagement";
import {
  UserContext,
  CognitoGroupContext,
} from "../../BusinessLogic/Hooks/UserContext";

import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";

const LandingPageV5 = (props) => {
  LandingPageV5.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string,
  };

  var vFirstTouch = true;
  const [isLoading, setIsLoading] = useState(true);
  const [vNavBarMenuClicked, setvNavBarMenuClicked] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const contentRef = useRef(null);
  const ScrollYRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const client = generateClient();
  const { Users, setUsers } = useContext(UserContext);

  var vLatitude = ""
  var vLongitude = ""

  var vProblemScroll = false;
  var vScrollToPage = "Hero";

  const ProblemRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ScrollYRef,
    offset: ["start start", "end end"],
  });


  useEffect(() => {
   // navigator.geolocation.getCurrentPosition(success, error);
    (async function () {
      try {
        getData();
      } catch (err) {
        console.error("LandingPageV5.useEffect[].ERROR: ", err, err.stack);
      }
    })();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current != null) {
        setContentHeight(contentRef.current.scrollHeight);
      }
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentRef]);

  const smoothProgress = useSpring(scrollYProgress, {
    mass: 0.1,
    stiffness: 20,
    dampling: 10,
    restDelta: 0.001,
    velocity: 100,
  });

  useEffect(() => {}, [smoothProgress]);

  useEffect(() => {
    /**
    console.log("LandingPageV5.useEffect[vProblemScroll].vProblemScroll: ", vProblemScroll)
    if(vProblemScroll === true) {
      const element = document.getElementById("Problem");
      console.log("LandingPageV5.useEffect[vProblemScroll].element: ", element)
      
      element?.scrollIntoView({
        behavior: "smooth"
      })
  } 
  */
  }, [vProblemScroll]);

  useMotionValueEvent(smoothProgress, "change", (latest) => {
    if (latest === 0) {
      setIsLoading(false);
    }
    
  });

  const y = useTransform(smoothProgress, (value) => {
    return value * -(contentHeight - windowHeight);
  });

  async function GoToSection(id) {
    try {
      //console.log("LandingPageV5.GoToSection.latest: ", latest)
      const element = document.getElementById(id);
      //  console.log("LandingPageV5.GoToSection.element: ", element)

      element?.scrollIntoView({
        behavior: "smooth",
      });
      /**
      window.scrollTo(0,1200)
*/
    } catch (err) {
      console.error("LandingPageV5.GoToSection.Error: ", err, err.stack);
    }
  }

  const getData = async () => {
    
    
    if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
      //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
      vFirstTouch = false;

      let DynamoUUID = await uuidv4();

      let AudienceGeoItem = {
        id: DynamoUUID,
        UserId: "NA",
        Page: "LandingPageV5",
        IPv4: "000.00.00.000",
        city: "NA",
        country_code: "NA",
        country_name: "NA",
        latitude: "00.000",
        longitude:  "00.000",
        postal: "NA",
        state: "NA",
        CreationName: "LandingPageV5",
        UpdateName: "LandingPageV5",
      };

      //console.log("LandingPageNavBar.getData.AudienceGeoItem: " ,AudienceGeoItem)

      try {
        let createAudienceGeoInfoResponse = await client.graphql({
          query: createAudienceGeoInfo,
          variables: {
            input: AudienceGeoItem,
          },
        });
      } catch (err) {
        RhyteItLogging(
          "NA",
          "LandingPageV5",
          "getData",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  };

  return (
    <div
      key="LandingPageV5"
      className={styles.BackgroundTop}
      style={{ height: contentHeight }}
    >
      <SEO
        title="RhyteIt: Smart Automation & AI Solutions for Adult Creators"
        description="Unlock your potential with RhyteIt, offering innovative automation and AI tools for adult content creators. Streamline your workflow and maximize profits effortlessly."
        name="RhyteIt"
        type="article"
      />
      <motion.div className={styles.NavBarRow}>
        <LogoName className={styles.LogoName} />
        <HamburgerMenu
          className={styles.Hamburger}
          NavBarMenuClicked={vNavBarMenuClicked}
          onClick={(e) => setvNavBarMenuClicked(!vNavBarMenuClicked)}
        />
      </motion.div>

      <motion.div
        className={styles.SmoothScrollDiv}
        ref={contentRef}
        style={{ y: isLoading ? 0 : y }}
      >
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="wrap"
          ref={ScrollYRef}
          className={styles.MasterContainer}
        >
          {vNavBarMenuClicked === false && (
            <Hero scrollYProgress={scrollYProgress} />
          )}
          {vNavBarMenuClicked === false && (
            <div id="Problem">
              <Problem scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="Villain">
              <Villain scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="Solution" className={styles.BenefitsButtonSection}>
              <Solution scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="OurStory">
              <OurStory scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="Benefits">
              <Benefits scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="PodcastEpisodes">
              <PodcastEpisodes scrollYProgress={scrollYProgress} />
            </div>
          )}          
          {vNavBarMenuClicked === false && (
            <div id="NewsLetter">
              <NewsLetter scrollYProgress={scrollYProgress} />
            </div>
          )}
          {vNavBarMenuClicked === false && (
            <div id="LetsTalk">
              <LetsTalk scrollYProgress={scrollYProgress} />
            </div>
          )}

          {vNavBarMenuClicked === true && <Menu />}
          {vNavBarMenuClicked === true && <FooterV2 />}
          {vNavBarMenuClicked === false && <Footer />}
        </Flex>
      </motion.div>
    </div>
  );
};

export default LandingPageV5;
