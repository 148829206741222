
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import beautify from 'js-beautify';

  import  {getNewsletter  }  from '../../../graphql/queries'
  import {    
    createNewsletter,
    updateNewsletterGeneration,
    deleteNewsletter
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './RhyteItTextAreaFieldJSON.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import NewsletterGeneration from './NewsletterGeneration';
  
function RhyteItTextAreaFieldJSON(
  props
) {       

  RhyteItTextAreaFieldJSON.propTypes = {
    Newsletter: PropTypes.object,
    NewsletterGeneration: PropTypes.object,
    RhyteItField: PropTypes.string,
    RhyteItFieldLabel: PropTypes.string,
    FieldValue: PropTypes.string,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    const [vNewsletterGeneration, setvNewsletterGeneration] = useState(props.NewsletterGeneration) 
    const [vRhyteItField, setvRhyteItField] = useState(props.RhyteItField) 
    const [vRhyteItFieldLabel, setRhyteItFieldLabel] = useState(props.RhyteItFieldLabel) 
    const [vFieldValue, setvFieldValue] = useState(props.FieldValue) 
    

    const [vUpdateKey, setvUpdateKey] = useState() 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    
  
        


      useEffect(() => {

        //console.log("RhyteItTextAreaFieldJSON.useEffect[].props.RhyteItField:  ",props.RhyteItField) 
        //console.log("RhyteItTextAreaFieldJSON.useEffect[].props.RhyteItFieldLabel:  ",props.RhyteItFieldLabel) 
        //console.log("RhyteItTextAreaFieldJSON.useEffect[].props.FieldValue:  ",props.FieldValue) 

      },[])  

      useEffect(() => {
      //console.log("RhyteItTextAreaFieldJSON.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("RhyteItTextAreaFieldJSON.useEffect[vNewsletter].vNewsletter: " ,vNewsletter);

        if (vNewsletter !== undefined) {

          
        
        }

      }, [vNewsletter]);   

      useEffect(() => {

        console.log("RhyteItTextAreaFieldJSON.useEffect[vFieldValue].vFieldValue: " ,vFieldValue);
 
         if (vFieldValue !== undefined) {
 
         // setvUpdateKey(Date.now)
         
         }
 
       }, [vFieldValue]);        
      
      
   
      async function UpdateRecord(pEvent, pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")

            
            //console.log("RhyteItTextAreaFieldJSON.UpdateRecord.pStatus: ",pStatus)
            //console.log("RhyteItTextAreaFieldJSON.UpdateRecord.vRhyteItField: ",vRhyteItField)
            //console.log("RhyteItTextAreaFieldJSON.UpdateRecord.vNewsletterGeneration: ",vNewsletterGeneration)
            
            var vUpdateDateTime = new Date().toISOString()

            let vStatus = pStatus + " " + vRhyteItField

            let NewsletterUpdateItem = {
              "id": vNewsletterGeneration["id"],
              [vRhyteItField]: vFieldValue,
              "NewsletterGenerationStatus": vStatus,
              "UpdateName": Users,
              "UpdateDateTime": vUpdateDateTime
            }

            
            
              let updateNewsletterGenerationResponse = await client.graphql({                    
                      query: updateNewsletterGeneration,
                      variables:{input: NewsletterUpdateItem}            
                }
              )
            
            //console.log("RhyteItTextAreaFieldJSON.SaveNewsletterEditionToSubscriber.updateNewsletterGenerationResponse: ",updateNewsletterGenerationResponse.data.updateNewsletterGeneration)
    

            setvNewsletterGeneration(updateNewsletterGenerationResponse.data.updateNewsletterGeneration)        
            
          } catch (err) { 
            RhyteItLogging(Users, "RhyteItTextAreaFieldJSON", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("RhyteItTextAreaFieldJSON.Unsubscribe.Error:  ",err); 
            console.error("RhyteItTextAreaFieldJSON.Unsubscribe.Error:  ",err.stack); 
        }        
      }    
      

    function Field_handleChange(event, data, pFieldName) {
      try {


        let vTmp = event.target.value

        setvFieldValue(vTmp)

      } catch (err) {
        RhyteItLogging(
          Users,
          "RhyteItTextAreaFieldJSON",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            
    
    const handleFormatJson = () => {
      try {
        
        const options = {
          indent_size: 2, // Indentation size
          wrap_line_length: 80, // Line wrap length
          preserve_newlines: true,
        };
        setvFieldValue(beautify.html(vFieldValue, options));
        setvUpdateKey(Date.now)

      } catch (err) {
        RhyteItLogging(
          Users,
          "RhyteItTextAreaFieldJSON",
          "handleFormatJson",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    };

    const MinifyJson = () => {
      try {
        setvFieldValue(vFieldValue.replace(/\s+/g, ' '));
        setvUpdateKey(Date.now)

      } catch (err) {
        RhyteItLogging(
          Users,
          "RhyteItTextAreaFieldJSON",
          "handleFormatJson",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    };
      return (          
        
                         
              <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>  
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>     
                <TableCell className={styles.TableCellLeftAlign} colspan="5">    
                <TextAreaField
                    className={styles.TextAreaField}
                    name={vRhyteItField}
                    defaultValue={vFieldValue}
                    rows={20}
                    resize="vertical"
                    autoResize
                    onChange={(e) => Field_handleChange(e, vFieldValue, vRhyteItField)}
                  />                           

                </TableCell>                                 
                </TableRow>
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}> 
                <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "UPDATED")}
                      
                    >
                    Save
                </Button> 
              </TableCell>   
              <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => handleFormatJson(e)}
                      
                    >
                    Format
                </Button> 
            </TableCell>    
              <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => MinifyJson(e)}
                      
                    >
                    Remove Spaces
                </Button> 
            </TableCell>  
            <TableCell  className={styles.TableCell}>  
              <Text  className={styles.TextUpdate}>
              {new Date(vNewsletterGeneration.UpdateDateTime).toISOString().slice(0, 10) + " " + new Date(vNewsletterGeneration.UpdateDateTime).toISOString().slice(11, 16)}
              </Text>                           
            </TableCell>                                                                        
            </TableRow>                                                        
            </TableRow>
                  
                    
    )
  }
export default RhyteItTextAreaFieldJSON;

