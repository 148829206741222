import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Divider,
  Image
  } from '@aws-amplify/ui-react';
  import styles from './Footer.module.css';  

const Footer = (props) => {

  Footer.propTypes = {
      pageSource: PropTypes.string
    };




useEffect(() => {
 

     
          
},[])        



// onMouseLeave={ (e) => ActionsContainerHoverAction(e, false) } 

  return (
    <div  className={styles.Footer}>      
      <footer>   
        <Divider className={styles.DividerFront} padding="xs" />     
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="3rem"
          className={styles.TopFlexRow}
        >            
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >   
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                className={styles.TermsRow}
            >                        
            <div className={styles.FooterCopyRight}>  
              <a  className={styles.FooterSMLink} href="mailto:support@rhyteit.com">support@rhyteit.com</a>
            </div>
            <div className={styles.FooterCopyRight}> 
              <span className={styles.FooterSMLink} id="copyrightYear">©2025 RhyteIt</span>
            </div>
            <div className={styles.FooterCopyRight}>        
              <a className={styles.FooterSMLink}href="https://www.rhyteit.com/blog">Blog</a>
              <span className={styles.Dots}> • </span>   
              <a className={styles.FooterSMLink}href="https://www.rhyteit.com/news">News</a>
              <span className={styles.Dots}> • </span>
              <a className={styles.FooterSMLink}href="https://twitter.com/RhyteIt">Twitter</a>
              <span className={styles.Dots}> • </span>
              <a className={styles.FooterSMLink}href="https://www.instagram.com/rhyteit/">Instagram</a>
              <span className={styles.Dots}> • </span>
              <a className={styles.FooterSMLink}href="https://discord.gg/Q4EbGsvNzA">Discord</a>
            </div>
            </Flex>   
          </Flex>          
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >     
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                className={styles.TermsRow}
            >      
              <div className={styles.FooterCopyRight}>  
                <a  className={styles.PageLink} href="mailto:support@rhyteit.com">Privacy Policy</a>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Payment & Delivery</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Terms of Use & Refund</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">About Us</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Contact Us</span>
              </div>
            </Flex>   
          </Flex>
        </Flex>
      </footer> 
      <div className={styles.space}/>
     </div>
  )
}

export default Footer;