
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {getNewsletter  }  from '../../../graphql/queries'
  import {    
    createNewsletter,
    updateNewsletter,
    deleteNewsletter
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './RhyteItTextField.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function RhyteItTextField(
  props
) {       

  RhyteItTextField.propTypes = {
    Newsletter: PropTypes.object,
    NewsletterFileURLs: PropTypes.object,
    RhyteItField: PropTypes.string,
    RhyteItFieldLabel: PropTypes.string,
    FieldValue: PropTypes.string,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter) 
    const [NewsletterFileURLs, setNewsletterFileURLs] = useState(props.NewsletterFileURLs) 
    const [vRhyteItField, setvRhyteItField] = useState(props.RhyteItField) 
    const [vFieldValue, setvFieldValue] = useState(props.FieldValue)     

    const [vUpdateKey, setvUpdateKey] = useState()    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    
  
        


      useEffect(() => {

        console.log("RhyteItTextField.useEffect[].props.RhyteItField:  ",props.RhyteItField) 
        console.log("RhyteItTextField.useEffect[].props.FieldValue:  ",props.FieldValue) 

      },[])  

      useEffect(() => {
      //console.log("RhyteItTextField.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("RhyteItTextField.useEffect[vNewsletter].vNewsletter: " ,vNewsletter);

        if (vNewsletter !== undefined) {

          setvUpdateKey(Date.now)
        
        }

      }, [vNewsletter]);   

      useEffect(() => {

        //console.log("RhyteItTextAreaFieldJSON.useEffect[vNewsletter].vNewsletter: " ,vNewsletter);
 
         if (vFieldValue !== undefined) {
 
         
         
         }
 
       }, [vFieldValue]);        
            
      
   
       async function SaveRecord(pEvent, pStatus) {
        
  
        try {           
            //console.log("RhyteItTextAreaFieldJSON.SaveRecord.pStatus: ",pStatus)
            //console.log("RhyteItTextAreaFieldJSON.SaveRecord.vRhyteItField: ",vRhyteItField)
            //console.log("RhyteItTextAreaFieldJSON.SaveRecord.NewsletterFileURLs: ",NewsletterFileURLs)

            NewsletterFileURLs[vRhyteItField] = vFieldValue
            
            //console.log("RhyteItTextAreaFieldJSON.SaveRecord.NewsletterFileURLs[vRhyteItField]: ",NewsletterFileURLs[vRhyteItField])
            let NewsletterFileURLsTmp = NewsletterFileURLs

            var today = new Date();

            var vUpdateDateTime = new Date().toISOString()

            let vStatus = pStatus + " " + vRhyteItField
      
            const vFileURLJSONtring = JSON.stringify(NewsletterFileURLsTmp); 


            let NewsletterUpdateItem = {
              "id": vNewsletter["id"],
              "FileURLs": vFileURLJSONtring,
              "NewsletterStatus": vStatus,
              "UpdateName": Users,
              "UpdateDateTime": vUpdateDateTime
            }


            console.log("RhyteItTextAreaFieldJSON.SaveNewsletterEditionToSubscriber.NewsletterUpdateItem: ",NewsletterUpdateItem)

            
              let updateNewsletterResponse = await client.graphql({                    
                      query: updateNewsletter,
                      variables:{input: NewsletterUpdateItem}            
                }
              )
           
            console.log("RhyteItTextAreaFieldJSON.SaveNewsletterEditionToSubscriber.updateNewsletterResponse: ",updateNewsletterResponse.data.updateNewsletter)
             
            setvNewsletter(updateNewsletterResponse.data.updateNewsletter)   
            setNewsletterFileURLs(updateNewsletterResponse.data.updateNewsletter)
               
            
          } catch (err) { 
            RhyteItLogging(Users, "RhyteItTextAreaFieldJSON", "SaveRecord",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("RhyteItTextAreaFieldJSON.SaveRecord.Error:  ",err); 
            console.error("RhyteItTextAreaFieldJSON.SaveRecord.Error:  ",err.stack); 
        }        
      }     
      

      function Field_handleChange(event, data, pFieldName) {
        try {
  
          setvFieldValue(event.target.value)
  
        } catch (err) {
          RhyteItLogging(
            Users,
            "RhyteItTextAreaFieldJSON",
            "vPrompt_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      return (          
        
                         
              <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>  
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>      
                <TableCell className={styles.TableCellLeftAlign} colspan="5"> 
                    <TextField
                        className={styles.TextField}
                        name={vRhyteItField}
                        defaultValue={vFieldValue}
                        onChange={(e) => Field_handleChange(e, vFieldValue, RhyteItTextField)}
                      />                  
                    </TableCell>  
                </TableRow>
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}> 
                <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => SaveRecord(e, "UPDATED")}
                      
                    >
                    Save
                </Button> 
              </TableCell>  
            <TableCell  className={styles.TableCellLeftAlign}>  
              <Text  className={styles.TextUpdate}>
              {new Date(vNewsletter.UpdateDateTime).toISOString().slice(0, 10) + " " + new Date(vNewsletter.UpdateDateTime).toISOString().slice(11, 16)}
              </Text>                           
            </TableCell>                                                                        
            </TableRow>                                                    
              </TableRow>
                  
                    
    )
  }
export default RhyteItTextField;

