
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex
  } from '@aws-amplify/ui-react'; 
import NewsletterPublisherRow from './NewsletterPublisherRow';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './NewsletterPublisherTable.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listNewsletterEditionTrackings,
  listNewsletterEditions,
  listNewsletterSubscribers,
} from "../../../graphql/queries";
import {
  createNewsletterEditionTracking,
  deleteNewsletterEditionTracking
} from "../../../graphql/mutations";


const NewsletterPublisherTable = (props) => {       

  NewsletterPublisherTable.propTypes = {
      Newsletter: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)   
    const [Users, setUsers] = useState(props.Users)   
    const [vSubscribers, setvSubscribers] = useState()  
    const [vSubscribersOriginal, setvSubscribersOriginal] = useState()    
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [vSubscribersRefresh, setvSubscribersRefresh] = useState()  
    let [vNewSubscriber, setvNewSubscriber] = useState();
    const [vAddSubscribersVisible, setvAddSubscribersVisible] = useState(false);
    const [updatedKey, setupdatedKey] = useState();
    const [vNewsletterEditionSelected, setvNewsletterEditionSelected] = useState({"Edition":"None"});
    let [vNewsletterEditionList, setvNewsletterEditionList] = useState();
    const [vNewsletterEditionVisible, setvNewsletterEditionVisible] = useState(false);
    const [vNewsletterEditions, setvNewsletterEditions] = useState()   
    const [vNewsletterSubscribers, setvNewsletterSubscribers] = useState()   

    
    const [vContentHTML, setvContentHTML] = useState('');
    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vContentJSON, setvContentJSON] = useState()  

    const [vUnsent, setvUnsent] = useState(0)  
    const [vBounced, setvBounced] = useState(0)  
    const [vOpened, setvOpened] = useState(0)  
    const [vUnopened, setvUnopened] = useState(0)  
    const [vSent, setvSent] = useState(0)  

    
    const [vS3Key, setvS3Key] = useState('');
    const [vEmailId, setvEmailId] = useState('');

    useEffect(() => {

    },[])  

    useEffect(() => {
      //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
   
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {

     //console.log("NewsletterPublisherTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)
      //console.log("NewsletterPublisherTable.useEffect[].vSubscribers ",vSubscribers) 
      //console.log("vNewsletterPublisherTable.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vNewsletterPublisherTable.useEffect[].Time:  ", NewsletterPublisherRow.PostDateTime.substr(NewsletterPublisherRow.PostDateTime.indexOf("T")+1, 5))
      //console.log("vNewsletterPublisherTable.useEffect[].indexOf:  ", NewsletterPublisherRow.PostDateTime.indexOf(":00"))
      if (vSubscribers !== undefined) {
        if (vSubscribers.length > 0) {
          setShowTable(true)        
          setvUpdateKey(Date.now())
          setvSubscribersRefresh(Date.now)
          }
      }

    },[vSubscribers])  

    useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)
  
      if (vNewsletter !== undefined) {

        const vTemplateJSONTmp = JSON.parse(vNewsletter["TemplateJSON"]);  
        setvTemplateJSON(vTemplateJSONTmp)

        GetNewsletterEditions()
        GetSubscribers()
        GetNewsletterTrackingTable()
      }
    }, [vNewsletter]);
  
    useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)

      if (vSubscribers !== undefined) {
        CalculateEditionStats()
       }
 
     },[vSubscribers])    
     
     useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vNewsletterSubscribers].vNewsletterSubscribers:  ",vNewsletterSubscribers)

     },[vNewsletterSubscribers])     
     

     useEffect(() => {
      console.log("NewsletterPublisherTable.useEffect[vNewsletterEditionSelected].vNewsletterEditionSelected[Edition]:  ",vNewsletterEditionSelected["Edition"])

      if (vNewsletterEditionSelected["Edition"] !== "None") {

          GetNewsletterTrackingTable()
          CreateMetaData()

          console.log("NewsletterPublisherTable.useEffect[vNewsletterEditionSelected].vNewsletterEditionSelected[EmailHTML]:  ",vNewsletterEditionSelected["EmailHTML"])

          setvContentHTML(vNewsletterEditionSelected["EmailHTML"])


       }
 
     },[vNewsletterEditionSelected])   
     



     async function CreateMetaData() {
        
  
      try {           
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()
      
          let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + Users+ "-" +vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
          let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + Users).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '') +"/"+ vId+".html"          
          
          setvEmailId(vId)
          setvS3Key(vS3Key)
  
        } catch (err) { 
          RhyteItLogging(Users, "NewsletterEditionPreview", "CreateNewsletterEditionTracking",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterEditionPreview.CreateNewsletterEditionTracking.Error:  ".err, err.stack); 
      }        
    } 
  

     async function GetNewsletterEditions() {
      try {
        setErrorMessage("");
        
        //console.log("""NewsletterEditorTable.GetNewsletterEditions.Users: ", Users);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterEditionsData2 = await client.graphql({
          query: listNewsletterEditions,
          variables: variables,
        });
  
        //console.log("""NewsletterEditorTable.GetNewsletterEditions.listNewsletterEditions.data.listNewsletterEditions.items.length : ", listNewsletterEditionsData2.data.listNewsletterEditions.items.length );
  
        if (listNewsletterEditionsData2.data.listNewsletterEditions.items.length > 0) {
          const NewsletterEditorTable1 =
            listNewsletterEditionsData2.data.listNewsletterEditions.items;
            let vSortedNewsletterEditions = SortNewsletterEditionsByName(NewsletterEditorTable1)
            setvNewsletterEditions(vSortedNewsletterEditions)
            setvNewsletterEditionList(vSortedNewsletterEditions)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "GetNewsletterEditions",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    function SortNewsletterEditionsByName(pNewsletterEditionEmail) {
      pNewsletterEditionEmail.sort(GFG_sortFunction);
      return pNewsletterEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let NewsletterEditionA = a.Edition;
      let NewsletterEditionB = b.Edition;
      return NewsletterEditionA > NewsletterEditionB ? 1 : -1;
    }

    async function GetNewsletterTrackingTable() {
      try {
        setErrorMessage("");

        setvSubscribers()
        
        console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.vNewsletterEditionSelected.Edition: ", vNewsletterEditionSelected["Edition"]);
        let variables;
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletterEditionSelected["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletterEditionSelected["Newsletter"],
              },
              Edition: {
                eq: vNewsletterEditionSelected["Edition"],
              },
            },
          };
        

        const listNewsletterEditionTrackingsData2 = await client.graphql({
          query: listNewsletterEditionTrackings,
          variables: variables,
        });
  
        console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.listNewsletterEditionTrackings.data.listNewsletterEditionTrackings.items.length : ", listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items.length );
  
        if (listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items.length > 0) {
          const NewsletterPublisherTable1 =
            listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items;
            let vSortedSubscribers = SortPublishedByUpdateDateTime(NewsletterPublisherTable1)
            setvSubscribers(vSortedSubscribers)
            setvSubscribersOriginal(vSortedSubscribers)
        } 
  
        
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "GetNewsletterTrackingTable",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    function SortPublishedByUpdateDateTime(pSubscriberEmail) {
      pSubscriberEmail.sort(UpdateDateTime_sortFunction);
      return pSubscriberEmail;
      //    //console.log(JSON.stringify(pEmails));
    }
  
    function UpdateDateTime_sortFunction(a, b) {
      let UpdateDateTimeA = a.UpdateDateTime;
      let UpdateDateTimeB = b.UpdateDateTime;
      return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
    }

    async function GetSubscribers() {
      try {
        setErrorMessage("");
        
        //console.log("NewsletterPublisherTable.GetSubscribers.vNewsletterEditionSelected: ", vNewsletterEditionSelected);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
              SubscriptionStatus: {
                eq: "subscribed",
              }
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterSubscribersData2 = await client.graphql({
          query: listNewsletterSubscribers,
          variables: variables,
        });
  
        //console.log("NewsletterPublisherTable.GetSubscribers.listNewsletterSubscribers.data.listNewsletterSubscribers.items.length : ", listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length );
  
        if (listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length > 0) {
          const NewsletterPublisherTable1 =
            listNewsletterSubscribersData2.data.listNewsletterSubscribers.items;
            setvNewsletterSubscribers(NewsletterPublisherTable1)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "GetSubscribers",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function CalculateEditionStats() {
      try {
        /** 
        const [vUnsent, setvUnsent] 
        const [vBounced, setvBounced] 
        const [vOpened, setvOpened] 
        const [vSent, setvSent] 
*/
        let counts = {
          sent: 0,
          unopened: 0,
          bounced: 0,
          opened: 0,
          unsent: 0
      };

      vSubscribersOriginal.forEach(vSubscriber => {
          switch (vSubscriber.DeliveryStatus) {
              case 'Delivery':
                  counts.unopened++;
                  break;
              case 'Bounce':
                  counts.bounced++;
                  break;
              case 'PUBLISH':
                  counts.unsent++;
                  break;
              case 'OPENED':
                  counts.opened++;
                  break;
              default:
                  console.log(`Unknown status: ${vSubscriber.DeliveryStatus}`);
                  break;
          }
      });


      counts.sent = counts.opened + counts.unopened + counts.bounced

      setvUnsent(counts.unsent)
      setvBounced(counts.bounced)
      setvOpened(counts.opened)
      setvSent(counts.sent)
      setvUnopened(counts.unopened)
      setvUpdateKey(Date.now())
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "CalculateEditionStats",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
      
    function SortSubscribersByName(pSubscriberEmail) {
      pSubscriberEmail.sort(GFG_sortFunction);
      return pSubscriberEmail;
      //    //console.log(JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let SubscriberEmailA = a.SubscriberEmail;
      let SubscriberEmailB = b.SubscriberEmail;
      return SubscriberEmailA > SubscriberEmailB ? 1 : -1;
    }
  
    async function ViewConfig(event) {
      try {
        //console.log("NewsletterPublisherTable.ViewConfig.event: ", event);
        //console.log("NewsletterPublisherTable.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
        setvAddSubscribersVisible(!vAddSubscribersVisible)
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "ViewConfig",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function NewsletterEditionSelectedAction(pNewsletterEdition) {
      try {
        console.log("NewsletterPublisherTable.NewsletterEditionSelectedAction.pNewsletterEdition: ", pNewsletterEdition);
        let vSelectedNewsletterGroup = vNewsletterEditionList.filter(
          (vNewsletterEditionItem) => vNewsletterEditionItem["Edition"] === pNewsletterEdition
        );
  
        console.log("NewsletterPublisherTable.NewsletterEditionSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
        //console.log("""NewsletterPublisherTable.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
        setvNewsletterEditionVisible(true);
        
        //setvNewsletterSelected(pAccount);
        setvNewsletterEditionSelected(vSelectedNewsletterGroup[0]);
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "NewsletterEditionSelectedAction",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }  

    async function IterateThroughSubscribers(pDeliveryStatus) {
      try {

        //console.log("NewsletterPublisherTable.IterateThroughSubscribers.vNewsletterEditionSelected[Edition]: ", vNewsletterEditionSelected["Edition"]);


        if (vSubscribers === undefined) {

          vNewsletterSubscribers.forEach(vNewsletterSubscriber => {
    
              SaveNewsletterEditionToSubscriber(vNewsletterSubscriber["SubscriberEmail"], pDeliveryStatus)
    
            })
        } else {


          vNewsletterSubscribers.forEach(vNewsletterSubscriber => {
           
            let vDoesSubscriberExistForThisEdition = vSubscribers.filter(
              (vSubscriber) => vNewsletterSubscriber["SubscriberEmail"] === vSubscriber["RecipientEmail"] && vNewsletterSubscriber["Newsletter"] === vSubscriber["Newsletter"] && vNewsletterSubscriber["AccountGroup"] === vSubscriber["AccountGroup"] && vNewsletterEditionSelected["Edition"] === vSubscriber["Edition"]
            );
    
            if (vDoesSubscriberExistForThisEdition.length === 0 && vNewsletterSubscriber["SubscriberEmail"] !== undefined) {
    
              SaveNewsletterEditionToSubscriber(vNewsletterSubscriber["SubscriberEmail"], pDeliveryStatus)
    
            }
    
            });


        }

       

      } catch (err) {
        console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "PublishNewsletter",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }  

    function convertToISO(datetimeStr) {
      // Create a new Date object from the provided string
      let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
      
      // Convert it to ISO string
      return date.toISOString();
    }  

    async function PublishNewsletter(event) {        
  
      try {           
      
          IterateThroughSubscribers("PUBLISH")


        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "PublishNewsletter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
      }        
    }  

    async function SendNewsletter() {        
  
      try {           
      
          IterateThroughSubscribers("SEND")


        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "SendNewsletter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
      }        
    }  

    async function TestNewsletter() {        
  
      try {           
      
        //SaveNewsletterEditionToSubscriber("ayitabegaye@gmail.com", "SEND")
        SaveNewsletterEditionToSubscriber("lisa@lisaxlopez.com", "SEND")
	
	
        

        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "SendNewsletter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
      }        
    }  


    async function SaveNewsletterEditionToSubscriber(pSubscriber, pDeliveryStatus) {
      try {

        console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.pSubscriber:  ",pSubscriber);
        var vUpdateDateTime = new Date().toISOString()

        let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + pSubscriber+ "-" +vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
        let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + pSubscriber).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '') +"/"+ vId+".html"          

  
        //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.vId:  ",vId);
        
        let vContentHTMLFinal = vNewsletterEditionSelected["EmailHTML"].replace(/<SubscriberEmail>/g, pSubscriber).replace(/<SubscriberEmailId>/g, vId)
        vContentHTMLFinal = vContentHTMLFinal.replace(/&lt;EmailHTML&gt;/g, pSubscriber).replace(/&lt;SubscriberEmailId&gt;/g, vId)
        
        console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.vContentHTMLFinal:  ",vContentHTMLFinal);
        
        let datetimeStr = "1999-01-01 01:00:00";
        let isoDate = convertToISO(datetimeStr);

        let vNewsletterEditionCreateItem = {
          "id": vId,
          "AccountGroup": vNewsletterEditionSelected["AccountGroup"],
          "CampaignName": vNewsletterEditionSelected["CampaignName"],
          "Newsletter": vNewsletterEditionSelected["Newsletter"],
          "Edition": vNewsletterEditionSelected["Edition"],
          "EmailHTML":  vContentHTMLFinal,
          "SESMessageId": "",
          "S3HtmlLocation": "",
          "S3Bucket": "rhyteitnewsletters",
          "S3Key": vS3Key,
          "RecipientEmail": pSubscriber,
          "SendDateTime": isoDate,
          "FirstOpenDateTime": isoDate,
          "OpenDateTime": isoDate,
          "LastClickDateTime": isoDate,
          "UnsubscribeDateTime": isoDate,
          "SendCount": 0,
          "OpenCount": 0,
          "ClickCount": 0,
          "DeliveryStatus": pDeliveryStatus,
          "BounceReason": "",
          "UnsubscribeStatus": false,
          "AudienceSegment": "Internal",
          "DeviceType": "",
          "UserAgent": "",
          "IPAddress": "",
          "City": "",
          "Country": "",
          "ContentVersion": 1,
          "NewsletterTrackingStatus": pDeliveryStatus,
          "CreationName": Users,
          "UpdateName": Users,
          "CreationDateTime": vUpdateDateTime,
          "UpdateDateTime": vUpdateDateTime
        }

         //console.log("NewsletterSubscriberTable.CreateNewsletterEditionTracking.vNewsletterEditionCreateItem: ",vNewsletterEditionCreateItem)

          let createNewsletterEditionTrackingResponse = await client.graphql({                    
                  query: createNewsletterEditionTracking,
                  variables:{input: vNewsletterEditionCreateItem}            
            }
          )
        
        //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.createNewsletterEditionTrackingResponse: ",createNewsletterEditionTrackingResponse)

        setvAddSubscribersVisible(false);
        setvNewSubscriber("");
        GetNewsletterTrackingTable()

      } catch (err) {
        console.error("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.Error:  ".err, err.stack); 
        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "CreateSubscriber",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }



    async function DeleteAllRows(event) {
      try {

        
        vSubscribers.forEach(vNewsletterSubscriber => {
        
          DeleteRow(vNewsletterSubscriber) 

          });

          GetNewsletterTrackingTable()
      
          } catch (err) { 

            RhyteItLogging(Users, "NewsletterSubscriberTable", "DeleteAllRows",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberTable.DeleteAllRows.Error:  ".err, err.stack);       
        }               
      
    }      

    async function DeleteRow(pSubsciber) {
      try {

              
              setErrorMessage("")   

              const DeleteNewsletterEditionTrackingRow = {
                id: pSubsciber.id,
                _version: pSubsciber._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteNewsletterEditionTracking,
                variables: {
                  input: DeleteNewsletterEditionTrackingRow
                }
              });  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)  

              
      
          } catch (err) { 

            RhyteItLogging(Users, "NewsletterSubscriberTable", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberTable.DeleteRow.Error:  ".err, err.stack);       
        }               
      
    }       

    async function ButtonFilter(event, pFilter) {
      try {

        let vSubscribersTmp = []

        if (pFilter === "SENT") {

          vSubscribersTmp = vSubscribersOriginal.filter(subscriber => subscriber.DeliveryStatus !== "PUBLISH" );

        } else {

          vSubscribersTmp = vSubscribersOriginal.filter(subscriber => subscriber.DeliveryStatus === pFilter);

        }
        

        setvSubscribers(vSubscribersTmp)
      
          } catch (err) { 

            RhyteItLogging(Users, "NewsletterSubscriberTable", "ButtonFilter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberTable.ButtonFilter.Error:  ".err, err.stack);       
        }               
      
    }      


    


  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vNewsletter !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
            

        <motion.div 
              className = {styles.NewsletterTitleDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterTitle}>
              {vNewsletter["Newsletter"]}
            </motion.h1>   
        </motion.div>             
        <motion.div 
              className = {styles.NewsletterDescriptionDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterDescription}>
              {vNewsletter["Description"]}
            </motion.h1>   
        </motion.div>  
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}             >      
        <SelectField
                className={styles.SelectField}
                value={vNewsletterEditionSelected.Edition}
                placeholder="Select newsletter edition"
                onChange={(e) => NewsletterEditionSelectedAction(e.target.value)}
              >
                {vNewsletterEditionList !== undefined &&
                  vNewsletterEditionList.map((NewsletterEdition) => (
                    <option
                      key={NewsletterEdition.Edition}
                      className={styles.SelectFieldOption}
                      value={NewsletterEdition.Edition}
                      label={NewsletterEdition.Edition}
                    >
                      {NewsletterEdition.Edition}
                    </option>
                  ))}
        </SelectField>    
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => TestNewsletter(e)}
              >
                Test
            </Button>        
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => SendNewsletter(e)}
              >
                Send
            </Button>   
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetNewsletterTrackingTable(e)}
              >
                Refresh
            </Button>     
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => DeleteAllRows(e)}
              >
                Delete All
            </Button>     
                     
        </Flex>       
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
            <TableCell className={styles.TableHeaderRowCells} >Unsent</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Sent</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Unopened</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Opened</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Bounced</TableCell> 
            </TableRow> 
          </TableHead>       
          <TableBody>         
            <TableRow  className={styles.TableRow}  key={vUpdateKey}>    
              <TableCell className={styles.TableCell}> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.Button}
                        onClick={(e) => ButtonFilter(e, "PUBLISH")}
                      >
                      {vUnsent}
                  </Button>   
              </TableCell>      
              <TableCell className={styles.TableCell}> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.Button}
                        onClick={(e) => ButtonFilter(e, "SENT")}
                      >
                      {vSent}
                  </Button>   
              </TableCell>        
              <TableCell className={styles.TableCell}> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.Button}
                        onClick={(e) => ButtonFilter(e, "Delivery")}
                      >
                      {vUnopened}
                  </Button>   
              </TableCell>              
              <TableCell className={styles.TableCell}> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.Button}
                        onClick={(e) => ButtonFilter(e, "OPENED")}
                      >
                      {vOpened}
                  </Button>   
              </TableCell>               
              <TableCell className={styles.TableCell}> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.Button}
                        onClick={(e) => ButtonFilter(e, "Bounce")}
                      >
                      {vBounced}
                  </Button>   
              </TableCell>                                                                                  
            </TableRow>         
            </TableBody>          
        </Table>              
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Expand</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Actions</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Email</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Sent</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >First Open</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Update</TableCell>
            </TableRow>                                      
          </TableHead>       
          
              {
                vSubscribers !== undefined && vNewsletter !== undefined && vSubscribers.map((Subscriber) => (                      
                  (<NewsletterPublisherRow   key={Subscriber.UpdateDateTime}  Users={Users} Newsletter={vNewsletter} Subscriber={Subscriber}/>)        
                  ))
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default NewsletterPublisherTable;


